<template>
    <div class="content-wrapper">
        <rq-banner
            variant="error"
            icon="fas fa-exclamation-triangle"
            :message="errorMessage"
            :visible="errorMessage.length > 0"
            @dismiss="errorMessage=''"
            dismissable
        />
        <div class="rq-master-detail-container">
            <section>
                <rqdx-action-data-grid
                    ref="dataGrid"
                    :title="itemTypeNamePlural"
                    title-size="sm"
                    automation_id="tbl_investment_accounts"
                    :actions="selectionActions"
                    :config="gridConfig"
                    :read-only="readOnly"
                    :data-source="gridDataSource"
                    @selectionChanged="onGridSelectionChanged"
                    @edit-memo="onEditEscrowMemo"
                    @delete="onDeleteItem"
                    @transfer-balance="onTransferBalance"
                    @transfer="onTransfer"
                    @view-report="onViewReport"
                    export-file-name="investment_accounts_data"
                    focus-after-insert="none"
                    integrated-search
                    rq-editable
                    rq-filters>
                    <template #toolbar>
                        <ul class="nav ms-2 me-auto">
                            <li class="nav-item">
                                <b-btn
                                    automation_id="btn_add"
                                    variant="theme"
                                    @click="onAddItem"
                                    :disabled="readOnly"
                                    >Add
                                </b-btn>
                            </li>
                        </ul>
                    </template>
                </rqdx-action-data-grid>
            </section>
            <section v-show="showDetail">
                <rqdx-action-data-grid
                    ref="detailDataGrid"
                    :title="selectedItemTitle"
                    title-size="sm"
                    automation_id="tbl_investment_ledger"
                    :actions="detailSelectionActions"
                    :config="detailGridConfig"
                    :data-source="detailGridDataSource"
                    @delete="onDeleteDetailItem"
                    :read-only="readOnly"
                    export-file-name="check_writing_investment_ledger_data"
                    focus-after-insert="none"
                    integrated-search
                    rq-editable
                    rq-filters>
                    <template #toolbar>
                        <ul class="nav">
                            <li class="nav-item">
                                <div class="dropdown rq-section-dropdown">
                                    <button class="btn btn-theme dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Add</button>
                                    <ul class="dropdown-menu">
                                        <li v-rq-tooltip.hover.left="!(disableAddCheck || readOnly) ? '' : 'Access Restricted'">
                                            <button type="button" class="dropdown-item"  automation_id="btn_add_disbursement" @click="onAddCheck" :disabled="disableAddCheck || readOnly" >Disbursement</button>
                                        </li>
                                        <li v-rq-tooltip.hover.left="!(disableAddDeposit || readOnly) ? '' : 'Access Restricted'">
                                            <button type="button" class="dropdown-item"  automation_id="btn_add_receipt" @click="onAddDeposit" :disabled="disableAddDeposit || readOnly" >Receipt</button>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </template>
                </rqdx-action-data-grid>
            </section>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import { InvestmentDto, InvestmentCheckDto, InvestmentDepositDto, InvestmentLedgerDto }  from "../models";
    import { UserScreenAccessLevel } from '@/shared/models/enums';
    import { ReportOptionsDto } from "@reporting/exago-reports/report-models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";

    export default {
        name:"InvestmentAccountMaintenance",
        props: {
            ordersID: { type: Number, required: true, default: 0 },
            bankCompanyName: { type: String, required: true, default: "" },
        },
        data () {
            return {
                addingCheck: true,
                items: [],
                detailItems: [],
                addEventName: "",
                errorMessage: "",
                selectedItem: {},
                selectionActions: [],
                detailSelectionActions: [],
            };
        },

        watch: {
            selectedItem(newValue, oldValue) {
                if(_.isEqual(newValue, oldValue)) return;
                this.refreshDetail();
            },
        },

        created() {
            this.initNonReactiveVariables();
            this.initGridConfig();
            this.fetchData();
        },

        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                isReadOnly: state => _.parseBool(state.isPageReadOnly, false),
                isFileLocked: state => _.parseBool(_.get(state, "orders.orderSummary.isLocked"), false),
                isEscrowLocked: state => _.parseBool(_.get(state, "orders.orderSummary.isEscrowLocked"), false),
                order: state => state.orders.order,
                user: state => state.authentication.session.user
            }),
            detailGridInstance() { return _.get(this, "$refs.detailDataGrid.gridInstance", null); },
            gridInstance() { return _.get(this, "$refs.dataGrid.gridInstance", null); },
            disableAddCheck() {
                return this.isReadOnly
                    || (this.localSecurity.CheckDepositSecurityAppliesToInvestmentAccount && !this.localSecurity.CanAddChecks)
                    || (!this.localSecurity.CheckDepositSecurityAppliesToInvestmentAccount && !this.localSecurity.AllowAddInvestmentAccount); },
            disableAddDeposit() {
                return this.isReadOnly
                    || (this.localSecurity.CheckDepositSecurityAppliesToInvestmentAccount && !this.localSecurity.CanAddDeposits)
                    || (!this.localSecurity.CheckDepositSecurityAppliesToInvestmentAccount && !this.localSecurity.AllowAddInvestmentAccount); },
            disableDeleteCheck() {
                return this.isReadOnly
                    || (this.localSecurity.CheckDepositSecurityAppliesToInvestmentAccount && !this.localSecurity.AllowDeleteCheck)
                    || (!this.localSecurity.CheckDepositSecurityAppliesToInvestmentAccount && !this.localSecurity.AllowDeleteInvestmentAccount); },
            disableDeleteDeposit() {
                return this.isReadOnly
                    || (this.localSecurity.CheckDepositSecurityAppliesToInvestmentAccount && !this.localSecurity.AllowDepositDelete)
                    || (!this.localSecurity.CheckDepositSecurityAppliesToInvestmentAccount && !this.localSecurity.AllowDeleteInvestmentAccount); },
            localSecurity(){
                return this.securitySettings.findValues([
                    "AllowAddInvestmentAccount",
                    "AllowDeleteCheck",
                    "AllowDeleteInvestmentAccount",
                    "AllowDepositDelete",
                    "AllowTransferFromInvestmentAccount",
                    "CheckDepositSecurityAppliesToInvestmentAccount",
                    "CanAddChecks",
                    "CanAddDeposits",
                    "CanEnterEditEscrowMemo",
                    "InvestmentAccountIndividual_ScreenAccess"
                    ]);
            },
            readOnly() { return this.isReadOnly || this.isFileLocked || this.isEscrowLocked || this.localSecurity.InvestmentAccountIndividual_ScreenAccess === UserScreenAccessLevel.Read },
            selectedKey() {
                if (_.isEmpty(this.selectedItem)) return 0;
                return _.get(this.selectedItem, this.itemKey, 0);
            },
            selectedItemTitle() {
                if (_.isEmpty(this.selectedItem)) return "";
                return `${_.get(this.selectedItem, "bankName")}: Ledger`;
            },
            selectedItemDetail() {
                return  this.showDetail ? _.filter(this.detailItems, [this.itemKey, this.selectedKey]) : [];
            },
            showDetail() { return this.selectedKey > 0; },
            totalChecks() { return _.sumBy(_.filter(this.selectedItemDetail, ["isCheck", true]), "amount"); },
            totalDeposits() { return _.sumBy(_.filter(this.selectedItemDetail, ["isCheck", false]), "amount"); },
        },

        methods: {
            createInvestmentCheck(amount){
                const self = this;

                if(amount <= 0) {
                    self.errorMessage = `Amount must be greater than zero.`;
                    return Promise.reject(self.errorMessage);
                }
                if(amount > self.selectedItemDetail[0].accountBalance) {
                    self.errorMessage = `Amount must be less than or equal to the account balance.`;
                    return Promise.reject(self.errorMessage);
                }

                let apiPromise = self.$api.CheckWritingApi.createInvestmentCheck(self.selectedKey, amount);
                return self.$rqBusy.wait(apiPromise);
            },

            elementName(prefix="", suffix="") { return _.snakeCase(`${prefix} ${this.itemTypeName} ${suffix}`); },

            fetchData() {
                const self = this;
                let apiPromise = self.$api.CheckWritingApi.getInvestmentData(self.ordersID);
                self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.items = _.map(result.investments, c => new InvestmentDto(c));
                        self.detailItems = _.map(_.concat(result.investmentChecks, result.investmentDeposits), c => new InvestmentLedgerDto(c));
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error({ message: `Error loading ${self.itemTypeName} Data.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            getNextInvestment() {
                let sequence = _.get(_.maxBy(this.items, "sequence"), "sequence", 0) + 1;
                return {sequence, ordersID: this.ordersID, accountBalance: 0, investmentID: 0 };
            },

            getNextDetailLine() {
                let sequence = _.get(_.maxBy(this.selectedItemDetail, "sequence"), "sequence", 0) + 1;
                let investmentID = this.selectedKey;
                let ledgerDate = DateTimeHelper.nowTenantStartOfDay();
                if (this.addingCheck) {
                    return {sequence, ledgerDate, checkDate: ledgerDate, depositDate: null, investmentID, investmentCheckID: 0, investmentDepositID: null, isCheck: true };
                } else {
                    return {sequence, ledgerDate, checkDate: null, depositDate: ledgerDate, investmentID, investmentCheckID: null, investmentDepositID: 0, isCheck: false };
                }
            },

            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    allowColumnReordering: false,
                    onEditorPreparing: self.onEditorPreparing,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [25,50,100], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    scrolling: { useNative: true },
                    columns: [
                        {
                            dataField: self.itemKey,
                            visible: false,
                            allowSearch: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "accountNumber",
                            dataType: "string",
                            validationRules: [
                                { type: "required" },
                            ],
                            editorOptions: { maxLength: 40 }
                        },
                        {
                            dataField: "bankName",
                            dataType: "string",
                            validationRules: [
                                { type: "required" },
                            ],
                            editorOptions: { maxLength: 65 }
                        },
                        {
                            dataField: "receipts",
                            dataType: "number",
                            allowEditing: false,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "disbursements",
                            dataType: "number",
                            allowEditing: false,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "accountBalance",
                            dataType: "number",
                            allowEditing: false,
                            format: {
                                type: "currency",
                                precision: 2
                            },
                        },
                        {
                            dataField: "escrowMemo",
                            dataType: "string",
                            allowEditing: true,
                            editorOptions: { maxLength: 250 }
                        },
                        DxGridUtils.dateColumn({
                            dataField: "lastActivity",
                            allowEditing: false,
                            width: 120
                        }),
                    ],
                    summary: {
                        totalItems: [
                            {
                                name: "TotalLabel",
                                column: "accountNumber",
                                alignment: "left",
                                displayFormat: "TOTAL",
                                cssClass: "rq-summary-label",
                                summaryType: "max"
                            },
                            {
                                name: "TotalBalance",
                                column: "accountBalance",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "sum"
                            },
                            {
                                name: "TotalReceipts",
                                column: "receipts",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "sum"
                            },
                            {
                                name: "TotalDisbursements",
                                column: "disbursements",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "sum"
                            },
                        ]
                    },
                    onInitNewRow(e) {
                        e.data = self.getNextInvestment();
                    },
                };

                self.gridDataSource = {
                    key: self.itemKey,
                    load (loadOptions) {
                        return Promise.resolve(self.items);
                    },
                    insert: self.onGridInsert,
                    update: self.onGridUpdate
                };
                self.detailGridConfig = {
                    onEditorPreparing: self.onDetailEditorPreparing,
                    focusedRowEnabled: false,
                    paging: { enabled: true },
                    pager: { showPageSizeSelector: true, allowedPageSizes: [25,50,100], showInfo: true},
                    remoteOperations: { sorting: false, paging: false },
                    columns: [
                        {
                            dataField: self.detailKey,
                            visible: false,
                            allowSearch: false,
                            allowEditing: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "sequence",
                            caption: "Line",
                            visible: false,
                            allowSearch: false,
                            allowEditing: false,
                            showInColumnChooser: false
                        },
                        {
                            dataField: "ledgerDate",
                            dataType: "date",
                            caption: "Date",
                            validationRules: [{ type: 'required' }],
                            width: 150,
                            showClearButton: true,
                        },
                        {
                            dataField: "description",
                            validationRules: [
                                { type: "required" },
                            ],
                            editorOptions: { maxLength: 40 }
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            width: 180,
                            cellTemplate: DxGridUtils.ledgerAmountCellTemplate,
                            validationRules: [
                                { type: "required" },
                                {
                                    type: "range",
                                    min: 0,
                                    message: "Must be greater than 0."
                                }
                            ],
                            editorOptions: { format: {type: "currency", precision: 2}, showClearButton: true },
                        },
                        {
                            dataField: "sortOrder",
                            sortIndex: 0,
                            sortOrder: "asc",
                            visible: false
                        },
                    ],
                    summary: {
                        totalItems: [
                            {
                                name: "TotalDepositsLabel",
                                column: "description",
                                alignment: "left",
                                displayFormat: "TOTAL RECEIPTS",
                                cssClass: "rq-summary-label",
                                summaryType: "sum"
                            },
                            {
                                name: "TotalDeposits",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "custom"
                            },
                            {
                                name: "TotalChecksLabel",
                                column: "description",
                                alignment: "left",
                                displayFormat: "TOTAL DISBURSEMENTS",
                                cssClass: "rq-summary-label",
                                summaryType: "sum"
                            },
                            {
                                name: "TotalChecks",
                                column: "amount",
                                alignment: "right",
                                valueFormat: {
                                    type: "currency",
                                    precision: 2
                                },
                                displayFormat: "{0}",
                                summaryType: "custom"
                            },
                        ],
                        calculateCustomSummary(options) {
                            if (options.name == "TotalDeposits") {
                                options.totalValue = self.totalDeposits;
                            } else if (options.name == "TotalChecks") {
                                options.totalValue = self.totalChecks;
                            }
                        }
                    },
                    onInitNewRow(e) {
                        e.data = self.getNextDetailLine();
                    },
                };

                self.detailGridDataSource = {
                    key: self.detailKey,
                    load (loadOptions) {
                        let items = _.filter(self.detailItems, [self.itemKey, self.selectedKey]);
                        return Promise.resolve(items);
                    },
                    insert: self.onDetailGridInsert,
                    update: self.onDetailGridUpdate,
                };
            },

            initNonReactiveVariables() {
                const self = this;
                self.itemTypeName = "Investment Account";
                self.itemTypeNamePlural = "Investment Accounts";
                self.itemKey = "investmentID";
                self.detailTypeNameDeposit = "Investment Deposit";
                self.detailTypeNameDepositPlural = "Investment Deposits";
                self.detailTypeNameCheck = "Investment Disbursement";
                self.detailTypeNameCheckPlural = "Investment Disbursements";
                self.detailKey = "investmentLedgerID";
                self.selectionActions = [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: `Delete ${self.itemTypeName}`,
                        disabled: function(e) {
                            return self.deleteDisabled(e);
                        }
                    },
                    {
                        name: "edit-memo",
                        text: "Edit Escrow Memo",
                        eventName: "edit-memo",
                        requireSelection: true,
                        allowMultiSelection: false,
                        disabled: function(e) {
                            if (self.readOnly) return 'Access Restricted';
                            return false;
                        }
                    },
                    {
                        name: "transfer",
                        text: "Transfer All",
                        eventName: "transfer-balance",
                        requireSelection: true,
                        tooltip: `Transfer Entire Current Balance back to Escrow Account`,
                        disabled: function(e) {
                            return self.transferDisabled(e);
                        }
                    },
                    {
                        name: "transfer",
                        text: "Transfer Amount",
                        eventName: "transfer",
                        requireSelection: true,
                        tooltip: `Transfer Amount back to Escrow Account`,
                        disabled: function(e) {
                            return self.transferDisabled(e);
                        }
                    },
                    {
                        name: "report",
                        text: "Print Ledger",
                        eventName: "view-report",
                        requireSelection: true,
                        tooltip: `Print Ledger`,
                    },
                ];
                self.detailSelectionActions = [
                    {
                        name: "delete",
                        text: "Delete",
                        eventName: "delete",
                        requireSelection: true,
                        allowMultiSelection: true,
                        tooltip: "Delete Item",
                        disabled: function(e) {
                            return self.deleteDetailSelectionDisabled(e);
                        }
                    }
                ];
            },

            deleteDisabled(e){
                const self = this;
                if (self.disableDeleteDeposit) return 'Access Restricted';
                return _.some(e.data, item => item.receipts > 0 || item.disbursements > 0);
            },

            transferDisabled(e) {
                const self = this;
                if(!self.localSecurity.AllowTransferFromInvestmentAccount) return 'Access Restricted';
                return (self.readOnly || _.get(e.data, "accountBalance", 0) <= 0);
            },

            deleteDetailSelectionDisabled(e){
                const self = this;
                if(self.disableDeleteDeposit || self.disableDeleteCheck) return 'Access Restricted';
                if(_.some(e.data, d => _.get(d, "sourceDepositID", 0) > 0)) return 'Access Restricted';
                if(_.some(e.data, d => _.get(d, "sourceChecksID", 0) > 0)) return 'Access Restricted';
                return (self.readOnly);
            },

            isUniqueItem(row){
                const self = this;
                let dup = {};
                dup = _.find(self.items, (i) => {
                    return  _.upperCase(_.trim(i.accountNumber)) === _.upperCase(_.trim(row.accountNumber))
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) != _.parseNumber(_.get(row, self.itemKey, -1), -1);
                });
                return _.isEmpty(dup);
            },

            isUniqueLine(row){
                const self = this;
                let dup = {};
                dup = _.find(self.detailItems, (i) => {
                    return  _.parseNumber(i.sequence, -1) === _.parseNumber(row.sequence, -1)
                            && _.parseNumber(_.get(i, "isCheck", false), -1) == _.parseNumber(_.get(row, "isCheck", false), -1)
                            && _.parseNumber(_.get(i, self.itemKey, -1), -1) == _.parseNumber(_.get(row, self.itemKey, -1), -1)
                            && _.get(i, self.detailKey, 'x') != _.get(row, self.detailKey, 'x');
                });
                return _.isEmpty(dup);
            },

            onAddItem(e) {
                if(!this.gridInstance) return;
                this.gridInstance.addRow();
            },

            onAddCheck(e) {
                if(this.disableAddCheck || this.readOnly) return;
                if(!this.detailGridInstance) return;
                this.addingCheck = true;
                this.detailGridInstance.addRow();
            },

            onAddDeposit(e) {
                if(this.disableAddDeposit || this.readOnly) return;
                if(!this.detailGridInstance) return;
                this.addingCheck = false;
                this.detailGridInstance.addRow();
            },

            onEditEscrowMemo(e) {
                if(!e || !e.data || this.readOnly) return;
                this.showEditEscrowMemoDialog(e.data);
            },

            onDeleteItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let deleteDisabled = self.deleteDisabled(e);
                if(deleteDisabled) return;
                let items = e.data;
                let ok = function (args) {
                    let ids = _.map(items, self.itemKey);
                    let apiPromise = self.$api.CheckWritingApi.deleteInvestments(self.ordersID, ids);
                    return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.items = _.map(result.investments, c => new InvestmentDto(c));
                            self.detailItems = _.map(_.concat(result.investmentChecks, result.investmentDeposits), c => new InvestmentLedgerDto(c));
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error deleting ${self.itemTypeName}.` });
                            return true;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${items.length > 1 ? self.itemTypeNamePlural : self.itemTypeName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onDeleteDetailItem(e) {
                if(!e || !e.data) return;
                const self = this;
                let deleteDetailSelectionDisabled = self.deleteDetailSelectionDisabled(e);
                if(deleteDetailSelectionDisabled) return;
                let ok = function (args) {
                    let ids = _.map(e.data, self.detailKey);
                    let apiPromise = self.$api.CheckWritingApi.deleteInvestmentLedgers(self.selectedKey, ids);
                    return self.$rqBusy.wait(apiPromise)
                        .then(result => {
                            self.items = _.map(result.investments, c => new InvestmentDto(c));
                            self.detailItems = _.map(_.concat(result.investmentChecks, result.investmentDeposits), c => new InvestmentLedgerDto(c));

                            let message = ids.length > 1 ? `${ids.length} Items were deleted.` : `Item was deleted.`
                            self.$toast.success({ message: message });
                            return true;
                        })
                        .catch(error => {
                            if (error.errorMessage.indexOf("REFERENCE constraint") > 0) {
                                 self.$dialog.confirm(`Delete Error`, `One or more of the selected ${self.detailTypeNameDepositPlural} are currently being used and could not be deleted.`);
                            } else {
                                self.$toast.error({ message: `Error deleting ${self.detailTypeNameDeposit}.` });
                            }
                            return true;
                        })
                        .finally(() => {
                            self.refresh(false);
                            self.refreshDetail();
                        });
                }

                self.$dialog.confirm("Confirm Delete", `Are you sure you want to delete the selected ${e.data.length > 1 ? "items" : "item"}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onGridInsert(values) {
                const self = this;
                let newItem = new InvestmentDto(values);
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.saveInvestment(newItem, changes)
                    .then(result => {
                        self.$toast.success({ message: `${self.itemTypeName} ${newItem.bankName} was created.` });
                        self.items.push(new InvestmentDto(result.investments[0]));
                    }).catch(error => {
                        self.$toast.error({ message: `Error creating ${self.itemTypeName}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onGridUpdate(key, values) {
                const self = this;
                let itemIndex = _.findIndex(self.items, [self.itemKey, key]);
                if(itemIndex < 0) return;

                let originalItem = _.cloneDeep(self.items[itemIndex]);
                let updatedItem = new InvestmentDto(_.assign({}, self.items[itemIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());

                return self.saveInvestment(updatedItem, changes)
                    .then(result => {
                        self.$toast.success({ message: `${self.itemTypeName} ${updatedItem.bankName} was saved.` });
                        let itemIndex = _.findIndex(self.items, [self.itemKey, _.get(result.investments[0], self.itemKey)]);
                        _.assign(self.items[itemIndex], new InvestmentDto(result.investments[0]));
                    }).catch(error => {
                        self.$toast.error({ message: `Error saving ${self.itemTypeName}.` });
                    })
                    .finally(() => {
                        self.refresh();
                    });
            },

            onDetailGridInsert(values) {
                const self = this;
                let newItem = new InvestmentLedgerDto(values);
                let noun = newItem.isCheck ? self.detailTypeNameCheck : self.detailTypeNameDeposit;
                let changes = _.map(values, (v,k) => ({ name: k, old: null, new: v }));
                return self.saveInvestmentLedger(newItem, changes)
                    .then(result => {
                        self.$toast.success({ message: `${noun} ${newItem.description} was created.` });
                        self.items = _.map(result.investments, c => new InvestmentDto(c));
                        self.detailItems = _.map(_.concat(result.investmentChecks, result.investmentDeposits), c => new InvestmentLedgerDto(c));
                    }).catch(error => {
                        self.$toast.error({ message: `Error creating ${noun}.` });
                    })
                    .finally(() => {
                        self.refresh(false);
                        self.refreshDetail();
                    });
            },

            onDetailGridUpdate(key, values) {
                const self = this;
                let detailIndex = _.findIndex(self.detailItems, [self.detailKey, key]);
                if(detailIndex < 0) return;

                let originalItem = _.cloneDeep(self.detailItems[detailIndex]);
                let updatedItem = new InvestmentLedgerDto(_.assign({}, self.detailItems[detailIndex], values));
                let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());
                let noun = originalItem.isCheck ? self.detailTypeNameCheck : self.detailTypeNameDeposit;
                return self.saveInvestmentLedger(updatedItem, changes)
                    .then(result => {
                        self.$toast.success({ message: `${noun} ${updatedItem.description} was saved.` });
                        self.items = _.map(result.investments, c => new InvestmentDto(c));
                        self.detailItems = _.map(_.concat(result.investmentChecks, result.investmentDeposits), c => new InvestmentLedgerDto(c));
                    }).catch(error => {
                        self.$toast.error({ message: `Error saving ${noun}.` });
                    })
                    .finally(() => {
                        self.refresh(false);
                        self.refreshDetail();
                    });
            },

            onEditorPreparing(e){
                if(e.parentType !== "dataRow") return;
                e.editorOptions.disabled = this.readOnly ;
            },

            onDetailEditorPreparing(e){
                if(e.parentType !== "dataRow") return;
                if(e.dataField === "description"){
                    e.editorOptions.disabled = this.readOnly;
                    return;
                }
                if(_.parseBool(_.get(e.row.data, 'isCheck', false))) {
                    e.editorOptions.disabled = this.readOnly || this.disableAddCheck || _.getNumber(e.row.data, 'sourceChecksID', 0) > 0;
                } else {
                    e.editorOptions.disabled = this.readOnly || this.disableAddDeposit || _.getNumber(e.row.data, 'sourceDepositID', 0) > 0;
                }
            },

            onGridSelectionChanged(e) {
                this.selectedItem = (_.get(e, "selectedRowsData.length", 0) == 1) ? e.selectedRowsData[0] : {};
            },

            onTransfer(e) {
                if(!e || !e.data) return;
                const self = this;
                let transferDisabled = self.transferDisabled(e);
                if(transferDisabled) return;
                this.showTransferAmountDialog(e.data.investmentID);
            },

            onTransferBalance(e) {
                if(!e || !e.data) return;
                const self = this;
                let transferDisabled = self.transferDisabled(e);
                if(transferDisabled) return;
                let item = e.data;
                let ok = function (args) {
                    self.createInvestmentCheck(item.accountBalance)
                        .then(result => {
                            self.items = _.map(result.investments, c => new InvestmentDto(c));
                            self.detailItems = _.map(_.concat(result.investmentChecks, result.investmentDeposits), c => new InvestmentLedgerDto(c));
                            // self.detailItems = _.map(result.investmentDeposits, c => new InvestmentDepositDto(c));
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error({ message: `Error transferring balance to Escrow Account.` });
                            return true;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                }

                self.$dialog.confirm("Confirm Transfer", `Are you sure you want to transfer the entire balance of $${item.accountBalance} back to ${self.bankCompanyName}?`, ok, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            onViewReport(e) {
                if(!e || !e.data) return;

                const self = this;

                let item = e.data;

                let investmentID = item.investmentID;

                let reportOptions = new ReportOptionsDto({
                    reportPath: 'System Reports/Accounting/Investment Account Receipts and Disbursement Ledger',
                    parameters: {
                        p_InvestmentID: investmentID,
                    },
                    immediate: true,
                });

                _.invoke(self, "$rq.showReport", reportOptions.reportPath, reportOptions);
            },

            refresh(clear=true) {
                if(!this.gridInstance) return;
                if (clear) {
                    this.gridInstance.option("focusedRowIndex", -1);
                    this.gridInstance.clearSelection();
                }
                this.gridInstance.refresh();
                this.gridInstance.updateDimensions();
            },

            refreshDetail() {
                if(!this.detailGridInstance) return;
                this.detailGridInstance.option("focusedRowIndex", -1);
                this.detailGridInstance.clearSelection();
                this.detailGridInstance.refresh();
                this.detailGridInstance.updateDimensions();
            },

            saveInvestment(item, changes){
                const self = this;

                if(changes.length === 0) {
                    return Promise.resolve(item);
                }
                if (!self.isUniqueItem(item)) {
                    self.errorMessage = `That ${self.itemTypeName} already exists.`;
                    return Promise.reject(self.errorMessage);
                }

                let apiPromise = self.$api.CheckWritingApi.saveInvestment(self.ordersID, item.toDataObject(), changes);
                self.errorMessage="";
                return self.$rqBusy.wait(apiPromise);
            },

            saveInvestmentLedger(item, changes){
                const self = this;
                let noun = self.addingCheck ? self.detailTypeNameCheck : self.detailTypeNameDeposit
                if(changes.length === 0) {
                    return Promise.resolve(item);
                }
                if (!self.isUniqueLine(item)) {
                    self.errorMessage = `That ${noun} already exists.`;
                    return Promise.reject(self.errorMessage);
                }

                let apiPromise = self.$api.CheckWritingApi.saveInvestmentLedger(self.ordersID, item.toDataObject(), changes);
                self.errorMessage="";
                return self.$rqBusy.wait(apiPromise);
            },

            showEditEscrowMemoDialog(item) {
                const self = this;
                let originalItem = self.selectedItem;
                let onOk = (e) => {
                    let updatedItem = _.clone(originalItem);
                    updatedItem.escrowMemo = e.value;
                    let changes = self.getAuditChanges(originalItem.toDataObject(), updatedItem.toDataObject());
                    self.saveInvestment(updatedItem, changes)
                        .then(result => {
                            self.$toast.success({ message: `${self.itemTypeName} ${updatedItem.bankName} was saved.` });
                            let itemIndex = _.findIndex(self.items, [self.itemKey, _.get(updatedItem, self.itemKey)]);
                            _.assign(self.items[itemIndex], updatedItem);
                            return true;
                        })
                        .catch(error => {
                            console.log(error);
                            self.$toast.error("Error saving Escrow Memo to Investment Account.");
                            return true;
                        })
                        .finally(() => {
                            self.refresh(false);
                        });
                };

                self.$dialog.promptInput({
                    title: `${item.bankName} Escrow Memo`,
                    label: "Escrow Memo",
                    value: originalItem.escrowMemo,
                    inputId: "txt_escrow_memo",
                    multiline: true,
                    isRequired: false,
                    onOk
                });
            },

            showTransferAmountDialog(investmentID) {
                const self = this;
                let investmentItem = _.find(self.items, { investmentID });
                let amount = _.parseNumber(investmentItem.accountBalance, 0);
                let onOk = (e) => {
                    let newAmount = _.parseNumber(e.value, 0);
                    self.createInvestmentCheck(newAmount)
                        .then(result => {
                            self.items = _.map(result.investments, c => new InvestmentDto(c));
                            self.detailItems = _.map(_.concat(result.investmentChecks, result.investmentDeposits), c => new InvestmentLedgerDto(c));
                            return true;
                        })
                        .catch(error => {
                            self.$toast.error("Error transferring balance to Escrow Account.");
                            return true;
                        })
                        .finally(() => {
                            self.refresh();
                        });
                };

                self.$dialog.promptInput({
                    title: "Transfer Amount",
                    inputId: "txt_transfer_amount",
                    label: "Amount",
                    value: amount,
                    inputType: "number",
                    editorOptions: {
                        formatType: "money",
                        minValue: 0,
                        decimals: 2,
                        inputGroup: true,
                        noPrefix: true,
                        prependIcon: "fas fa-dollar-sign"
                    },
                    validators: {
                        isValidAmount: {
                            validator: val => val <= amount,
                            message: `Transfer amount cannot be more than the Account Balance (${accounting.formatMoney(amount)}).`
                        },
                        isPositiveAmount: {
                            validator: val => val > 0,
                            message: "Transfer amount must be greater than 0."
                        }
                    },
                    onOk
                });
            },
        }
    }
</script>
