<template>
    <rqdx-action-data-grid v-if="!propIsNull"
        ref="invoicePaymentDataGrid"
        title="Payments"
        automation_id="tbl_invoice_payments"
        :actions="invoicePaymentSelectionActions"
        :data-source="invoicePaymentGridDataSource"
        :config="invoicePaymentGridConfig"
        export-file-name="invoice-payment-data"
        @delete="onDeletePaymentAction"
        hide-search
        :rq-editable="canEditInvoicePayments"
        hide-show-column-chooser
    >
    <template #toolbar>
        <ul class="nav navbar-nav me-auto">
            <li class="nav-item">
                <b-btn
                    automation_id="btn_add_invoice_payment"
                    variant="theme"
                    size="sm"
                    :disabled="disableAddPayment"
                    @click="onAddInvoicePayment">Add
                </b-btn>
            </li>
        </ul>
    </template>
    </rqdx-action-data-grid>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import { InvoicePaymentModel } from "../models";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";
    import GridSystemLookupMixin from "@/shared/mixins/GridSystemLookupMixin";
    import GridInvokerMixin from "@/shared/mixins/GridInvokerMixin";
    import {DateTime} from "luxon";

    export default {
        name: "InvoicePaymentSection",
        props:{
            invoiceDetail:{ type: Object },
            originalData:{ type: Object }
        },
        mixins: [GridSystemLookupMixin, GridInvokerMixin({ grid: "invoicePaymentDataGrid" })],
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                orderId: state => state.orders.orderId,
                readOnly: state => _.parseBool(state.isPageReadOnly),
                isOrderLocked: state => _.getBool(state, "orders.orderSummary.isLocked"),
                systemDefaults: state => state.system.systemDefaults,
                order: state => state.orders.order,
                loans: state => state.orders.loans,
            }),
            propIsNull(){ return _.isNil(this.invoiceDetail); },
            localSecurity(){ return this.securitySettings.findValues(["InvoicePayments", "PaymentPreDateDays", "PaymentPostDateDays"]); },
            disableAddPayment(){ return !(this.localSecurity.InvoicePayments > 0) || this.readOnly || this.isOrderLocked; },
            canEditInvoicePayments() { return this.localSecurity.InvoicePayments === 2 && !this.readOnly; },
            minPaymentDate() { return this.getMinMaxPaymentDate("min"); },
            maxPaymentDate() { return this.getMinMaxPaymentDate("max"); }
        },
        created() {
            const self = this;
            self.loadGridConfig();
        },
        methods:{
            getMinMaxPaymentDate(minOrMax) {
                const self = this;
                if(minOrMax !== "min" && minOrMax !== "max") return null;

                let dayDiff = _.parseNumber(minOrMax === "min"
                    ? self.localSecurity.PaymentPreDateDays
                    : self.localSecurity.PaymentPostDateDays, -1);

                if(dayDiff < 0) return null;

                if(minOrMax === "min") dayDiff *= -1;
                return DateTime.now().plus({ days: dayDiff }).toISO();
            },
            loadGridConfig(){
                const self = this;
                self.invoicePaymentSelectionActions = [
                    { name: "delete", text: "Delete", eventName: "delete", requireSelection: true, allowMultiSelection: true, disabled: () => !self.canEditInvoicePayments || self.disableAddPayment }
                ];

                self.invoicePaymentGridConfig = {
                    onInitNewRow: self.initNewPaymentRow,
                    onEditorPreparing: self.onPayementEditorPreparing,
                    columns: [
                        {
                            dataField: "referenceNumber",
                            caption: "Reference Number",
                            width: 150,
                            minWidth: 150,
                            allowEditing: true,
                            editorOptions:{ inputAttr: {automation_id: "txt_reference_number"}},
                            validationRules: [{
                                type: "stringLength",
                                max:50,
                                message:"Reference Number is limited to 50 characters"
                            }]
                        },
                        {
                            dataField: 'paymentDate',
                            dataType: "date",
                            caption: "Date",
                            width: 105,
                            minWidth: 105
                        },
                        {
                            dataField: "payment",
                            caption: "Amount",
                            allowEditing: true,
                            alignment: "right",
                            width: 150,
                            minWidth: 150,
                            dataType: "number",
                            cellTemplate: DxGridUtils.moneyCellTemplate,
                            format: { type: "currency", precision: 2 },
                            editorOptions: { format: {type: "currency", precision: 2}, showClearButton: true, inputAttr: {automation_id: "txt_amount"}},
                            precision: 2
                        },
                        self.getSystemLookupGridColumn({
                            column: {
                                dataField: "typeFundID",
                                dataType: "number",
                                caption: "Type of Funds",
                                width: 150,
                                minWidth: 150,
                                editorOptions:{ inputAttr: {automation_id: "txt_type_of_funds"} },
                            },
                            lookupKey: self.lookupItems.INVOICE_TYPE_FUNDS
                        }),
                        // {
                        //     dataField: "typeFundID",
                        //     caption: "Type of Funds",
                        //     width: 150,
                        //     minWidth: 150,
                        //     lookup: {
                        //         dataSource: self.lookupHelpers.getLookupItems(self.lookupItems.TYPE_FUNDS),
                        //         displayExpr: "name",
                        //         valueExpr: "id"
                        //     },
                        //     editorOptions:{ inputAttr: {automation_id: "txt_type_of_funds"} },
                        // },
                        {
                            dataField: "note",
                            caption: "Note",
                            allowEditing: true,
                            width: "auto",
                            editorOptions:{ inputAttr: {automation_id: "txt_note"} },
                        },
                    ],
                    summary:{
                        totalItems:[{
                            column: "payment",
                            summaryType: "sum",
                            displayFormat: "{0}",
                            valueFormat: { type:"currency", precision:2 }
                        }]
                    }
                }
                self.invoicePaymentGridDataSource = {
                    key: "clientKey",
                    load (loadOptions) {
                        return Promise.resolve(self.invoiceDetail.invoicePayments);
                    },
                    update: self.onPaymentGridUpdate,
                    insert: self.onPaymentGridInsert
                }
            },

            initNewPaymentRow(e){
                e.data = {
                    isNew: true,
                    paymentDate: DateTimeHelper.now()
                };
            },

            onPaymentGridUpdate(key, values) {
                const self = this;
                let item = _.find(self.invoiceDetail.invoicePayments, p => p.clientKey === key);
                _.assign(item, values);

                return Promise.resolve(item);
            },

            onPaymentGridInsert(values){
                const self = this;
                let newItem = new InvoicePaymentModel({
                    invoiceID: self.invoiceDetail.invoiceID
                });

                _.assign(newItem, values);
                self.invoiceDetail.invoicePayments.push(newItem);
                return Promise.resolve(newItem);
            },

            onPayementEditorPreparing(e){
                const self = this;
                let isNew = _.getBool(e, "row.data.isNew");
                if(e.dataField === "paymentDate"){
                    e.editorOptions.min = self.minPaymentDate;
                    e.editorOptions.max = self.maxPaymentDate;
                }
                if(isNew) return;
                e.editorOptions.disabled = !self.canEditInvoicePayments;
            },

            onAddInvoicePayment(){
                if(this.disableAddPayment) return;
                this.invokeGridMethod("addRow");
            },

            onDeletePaymentAction(e){
                const self = this;
                if(!e || !e.data || !self.canEditInvoicePayments || self.disableAddPayment) return;
                let items = e.data;

                let confirmMessage = items.length === 1 ? "Are you sure you want to delete this invoice payment?" : "Are you sure you want to delete these invoice payments?";

                let okHandler = function () {

                    _.forEach(items, i => {

                        let index = _.findIndex(self.invoiceDetail.invoicePayments, p => {
                            return p.clientKey === i.clientKey;
                        });

                        self.invoiceDetail.invoicePayments.splice(index, 1);

                        if(!i.isNew){
                            self.invoiceDetail.deletedInvoicePayments.push(i);
                        }

                    });

                    self.invokeGridMethod("refresh");
                    return true;
                };
                self.$dialog.confirm( "Delete Payment", confirmMessage, okHandler, null, { cancelTitle: 'No', okTitle: 'Yes'});
            },
            refresh(){
                if(this.propIsNull) return;
                this.invokeGridMethod("refresh");
            },
            onSave(e){
                return this.invokeGridMethod("saveEditData");
            },
            updateDimensions(){
                this.invokeGridMethod("updateDimensions");
                this.invokeGridMethod("repaint");
            }
        }
    }
</script>