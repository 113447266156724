<template>
    <div class="content-wrapper">
        <order-summary :hiddenActions="hiddenActions" />
        <rq-scroll-container
            ref="scrollContainer"
            :class="{ 'oe-scroll-container':true, 'oe-back-to-top':backToTopEnabled }"
            :hide-top-button="!backToTopEnabled">
            <router-view @updateHiddenActions="onUpdateHiddenActions"></router-view>
        </rq-scroll-container>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { ORDER_ACTIONS } from '@/store/actions';
    import { OrderStatus } from "@/shared/models/enums";
    import { OrderSummary } from "../shared/components";
    import { Actions as HeaderActions } from "@/router/actions/order-entry";

    export default {
        name: "OrderEntry",
        components: { OrderSummary },

        data() {
            return {
                hiddenActions: []
            };
        },

        computed: {
            ...mapState({
                order: state => state.orders.order
            }),
            backToTopEnabled() { return !_.parseBool(_.get(this, "$route.meta.disableBackToTop", false), false); },
            r2cStatus() {
                if(_.parseNumber(this.order.titleCompanyCompanyID, 0) == 0) return 0;
                if(!this.order.ready2CloseEnabled) return 0;
                if(!this.order.isReady2Close) return 1;
                else return 2;
            }
        },

        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },

        created() {
            const self = this;
            let hiddenActions = [];
            switch(self.r2cStatus) {
                case 0: // r2c not enabled
                    hiddenActions = [
                        HeaderActions.DIVIDER.key,
                        HeaderActions.READY2CLOSE.key,
                        HeaderActions.PUBLISH.key
                    ];
                    break;
                case 1: // order not published
                    hiddenActions = [ HeaderActions.READY2CLOSE.key, HeaderActions.R2C_SPACER.key ];
                    break;
                case 2: // order published
                    hiddenActions = [ HeaderActions.PUBLISH.key, HeaderActions.R2C_SPACER.key ];
                    break;
            }
            self.hiddenActions = hiddenActions;
        },

        watch:{
            "r2cStatus": {
                immediate: true,
                handler(newValue, oldValue) {
                    if (_.isNil(oldValue)) return;
                    let hiddenActions = [];
                    switch(newValue) {
                        case 0: // r2c not enabled
                            hiddenActions = [
                                HeaderActions.DIVIDER.key,
                                HeaderActions.READY2CLOSE.key,
                                HeaderActions.PUBLISH.key
                            ];
                            break;
                        case 1: // order not published
                            hiddenActions = [ HeaderActions.READY2CLOSE.key, HeaderActions.R2C_SPACER.key ];
                            break;
                        case 2: // order published
                            hiddenActions = [ HeaderActions.PUBLISH.key, HeaderActions.R2C_SPACER.key ];
                            break;
                    }
                    this.hiddenActions = hiddenActions;
                },
            },
        },

        methods: {
            onUpdateHiddenActions(e) {
                let actionKeys = _.get(e, "actions", []) || [];
                this.hiddenActions = actionKeys;
            },

            // openClosingMarket() {
            //     const self = this;
            //     let cmUrl = self.systemDefaults.CMPluginURL;
            //     //self.systemDefaultService.getCMUrl().then(cmUrl => {
            //         var frm = document.createElement("form");
            //         frm.target = "_blank";
            //         frm.method = "POST";
            //         frm.action = cmUrl;

            //         //
            //         var cmUser = document.createElement("input");
            //         cmUser.type = "text";
            //         cmUser.name = "userName";
            //         cmUser.value = "JW5678@RQ.com"; // TODO get the value from the users table

            //         var cmPwd = document.createElement("input");
            //         cmPwd.type = "text";
            //         cmPwd.name = "password";
            //         cmPwd.value = "FV6yryj8F7C08hl"; // TODO get the value from the users table

            //         var cmOrderId = document.createElement("input");
            //         cmOrderId.type = "text";
            //         cmOrderId.name = "orderID";
            //         cmOrderId.value = self.order.ordersID;

            //         var cmOrderDataFormat = document.createElement("input");
            //         cmOrderDataFormat.type = "text";
            //         cmOrderDataFormat.name = "orderDataFormat";
            //         cmOrderDataFormat.value = "RamQuestONE";

            //         var cmRamQuestProduct = document.createElement("input");
            //         cmRamQuestProduct.type = "text";
            //         cmRamQuestProduct.name = "RamQuestProduct";
            //         cmRamQuestProduct.value = "RQONE";

            //         frm.appendChild(cmUser);
            //         frm.appendChild(cmPwd);
            //         frm.appendChild(cmOrderId);
            //         frm.appendChild(cmOrderDataFormat);
            //         frm.appendChild(cmRamQuestProduct);

            //         document.body.appendChild(frm);

            //         frm.submit();
            //     //});
            // },

            // onConfirmCancel(e) {
            //     const self = this;
            //     if (e.hasChanges()) {
            //         self.$dialog.confirm("Confirm Cancel", "Discard changes and reload data?", e.callback);
            //     } else {
            //         self.$toast.info("No Changes Detected.");
            //     }
            // }
        }
    };

</script>