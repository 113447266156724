<template>
    <router-view :key="routeComponentKey"></router-view>
</template>

<script>
    import { mapState } from "vuex";
    import { GlobalEventManager } from "@/app.events";
    import { DateTime } from "luxon";
    import { ORDER_ACTIONS } from '@/store/actions';
    import { UserAccessLevel, OrderNoteCategory, OrderServiceType } from "@/shared/models/enums";
    import { OrderNote } from "./order-entry/models";
    import { ORDER_MUTATIONS } from "@/store/mutations";
    import AddEditOrderNote from "./notes/AddEditOrderNote";
    import OrderNotes from "./notes/OrderNotes";
    import { ApplyTemplate } from "./shared/components";
    import AuditLog from "./order-entry";
    import AltaPolicyOptions from "./title-policy/components/policy/AltaPolicyOptions";
    import TexasPolicyOptions from "./title-policy/components/policy/TexasPolicyOptions";

    export default {
        name: "FileMain",

        data() {
            const self = this;
            return {
                canUseOrderTemplate: false,
                applyTemplateTitles: {
                    oe: "Apply Template",
                    tp: "Apply Title Production/Policy Template",
                    op: "Apply Property Template",
                    bu: "Apply Buyer Template",
                    se: "Apply Seller Template",
                    ol: "Apply Loan Template",
                    os: "Apply Settlement Statement Template",
                    cs: "Apply CSS Template",
                },
                routeComponentKey: 1
            };
        },

        computed: {
            ...mapState({
                order: state => state.orders.order,
                commitment: state => state.orders.commitment,
                readOnly: state => state.isPageReadOnly,
                isOrderLocked: state => _.parseBool(state.orders.orderSummary.isLocked),
                loans: state => state.orders.loans || [],
                isAlta: state => _.getBool(state, "orders.orderSummary.isAlta"),
            }),
            noteCategory() { return _.getNumber(this, "$route.meta.noteCategory", 0); },
            localSecurity() { return this.securitySettings.findValues(["CanUseOrderTemplate"]); }
        },

        created() {
            const self = this;
            GlobalEventManager.onConfirmCancel(self, self.onConfirmCancel);
            GlobalEventManager.onFireAction(self, self.handleAction);
            GlobalEventManager.onUpdateOrder(self, self.onUpdateOrder);

            self.$events.on("apply-template", self.showApplyTemplate);
            self.$events.on("cdf-editor:reload-route", self.updateRouteKey);
        },

        beforeUnmount() {
            GlobalEventManager.unregister(this);
        },

        methods: {
            updateRouteKey() {
                const MIN_RELOAD_WINDOW = 5;//seconds
                let timestamp = DateTime.now().toUnixInteger();
                if(timestamp - this.routeComponentKey < MIN_RELOAD_WINDOW) return;
                this.routeComponentKey = timestamp;
            },
            handleAction(e) {
                if(e.disableWhenReadOnly && this.readOnly) return;
                switch (e.key) {
                    case "addNote":
                        this.showNoteDialog();
                        break;
                    case "editNote":
                        this.showNoteDialog(e.data);
                        break;
                    case "viewNotes":
                        this.showManageNoteDialog();
                        break;
                    case "applyTemplate":
                        if(!this.localSecurity.CanUseOrderTemplate) return;
                        this.showApplyTemplate();
                        break;
                    case "setPolicyOptions":
                        this.showPolicyOptionDialog();
                        break;
                    case "publishToR2C":
                        this.publishToR2c();
                        break;
                    case "viewInR2C":
                        this.viewInR2c();
                        break;
                    case "unpublishFromR2C":
                        this.unpublishFromR2C();
                        break;
                    case "downloadR2CActivity":
                        this.downloadR2CActivity();
                        break;
                    case "pushToR2C":
                        this.pushToR2C();
                        break;
                }
            },

            showManageNoteDialog() {
                const self = this;
                let title = this.noteCategory > 0
                    ? `${OrderNoteCategory.displayValue(self.noteCategory)} Notes`
                    : "Notes";
                self.$dialog.open({
                    title,
                    props: {
                        category: self.noteCategory
                    },
                    height: "80%",
                    width: "85%",
                    scrollable: false,
                    component: OrderNotes,
                    okOnly: true,
                    okTitle: "Close"
                });
            },

            getNewNote() {
                const self = this;
                return new OrderNote({
                    ordersID: self.order.ordersID,
                    category:self.noteCategory,
                    canPublish: self.securitySettings.findValue('OrderNotesPublishing') !== UserAccessLevel.None,
                    canEdit: true
                });
            },

            showNoteDialog(noteObj=null) {
                const self = this;
                let note = _.isNil(noteObj) ? self.getNewNote() : new OrderNote(noteObj);
                let dialogId = null;
                let refreshOnExit = false;
                let onOk = async function(e, addAnother=false) {
                    let success = await e.component.save();
                    if(!success) return false;
                    if (addAnother) {
                        self.$dialog.reloadComponent({
                            dialogId,
                            props: {
                                note: self.getNewNote()
                            }
                        });
                        refreshOnExit = true;
                        return false;
                    }
                    self.$events.emit("note-save-complete", { refresh: true });
                    return success;
                };

                let onCancel = function(e) {
                    if(!refreshOnExit) return true;
                    self.$events.emit("note-save-complete", { refresh: refreshOnExit });
                }

                let buttons = _.dialogButtons({ showAddAnother: note.isNew, onOk, onCancel });
                let title = this.noteCategory > 0
                    ? `${note.isNew ? "Add" : "Edit"} ${OrderNoteCategory.displayValue(self.noteCategory)} Note`
                    : `${note.isNew ? "Add" : "Edit"} Note`;

                dialogId = self.$dialog.open({
                    title,
                    height: "80%",
                    width: "75%",
                    scrollable: false,
                    props: { note },
                    component: AddEditOrderNote,
                    buttons
                });
            },

            showAuditLog() {
                const self = this;
                 self.$dialog.open({
                     title: "Audit Log",
                     height: "82%", // height: "770px",
                     width: "61%", // width: "1150px",
                     component: AuditLog,
                     args: {
                         orderId: self.order.ordersID
                     },
                     resizable: false,
                     draggable: false,
                     okOnly: true,
                     okTitle: "Close",
                     closeOnEsc: true
                 });
            },

            showApplyTemplate() {
                const self = this;
                let category = _.get(self, "$route.meta.templateCategory", "oe") || "oe";
                let loanIdParam =  _.getNumber(self, "$route.params.loanId", null);
                self.$dialog.open({
                    title: self.applyTemplateTitles[category],
                    height: "80%",
                    width: "80%",
                    resizable: false,
                    component: ApplyTemplate,
                    props: {
                        category,
                        targetId: self.order.ordersID,
                        targetLoanId: category === "tp" || category === "cs"
                            ? loanIdParam ? loanIdParam : self.loans[0].loanID
                            : null
                    },
                    closeOnEsc: false,
                    onOk(e) {
                        return e.component.confirmApplyTemplate().then(result => {
                            if(result){
                                self.$events.emit("apply-template-success");
                                self.$toast.success("Template applied successfully.");
                            }
                            return result;
                        });
                    }
                });
            },

            showPolicyOptionDialog() {
                let self = this;
                let serviceType = self.order.service;
                let isOwner = OrderServiceType.isOwnerSpecific(serviceType);
                let isLoan = OrderServiceType.isLoanSpecific(serviceType);
                let isSimultaneousEligible = OrderServiceType.isSimultaneousEligible(serviceType);

                let optionSettings = {
                    isSimultaneousOrOwner: isSimultaneousEligible || isOwner,
                    isSimultaneousOrLoan: isSimultaneousEligible || isLoan
                };


                self.$dialog.open({
                    title: `${self.isAlta ? "ALTA" : "Texas"} Commitment / Policy Options`,
                    height: "auto",
                    component: self.isAlta ? AltaPolicyOptions : TexasPolicyOptions,
                    props: {
                        settings: optionSettings,
                        commitmentData: self.commitment,
                        loans: self.loans
                    },
                    closeOnEsc: false,
                    onOk(e) {
                        return e.component.save()
                            .then(success => {
                                return success;
                            });
                    }
                });

            },

            onUpdateOrder(order) {
                this.$store.dispatch(ORDER_ACTIONS.GET_ORDER, { refresh: true });
            },

            onConfirmCancel(e) {
                const self = this;
                if (e.hasChanges()) {
                    self.$dialog.confirm("Confirm Cancel", "Discard changes and reload data?", e.callback);
                } else {
                    self.$toast.info("No Changes Detected.");
                }
            },

            publishToR2c() {
                const self = this;

                let apiPromise = self.$api.Ready2CloseChangeTrackingApi.publishOrder(self.order.ordersID);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let order = _.clone(self.order);
                        order.isReady2Close = true;
                        self.$store.commit(ORDER_MUTATIONS.SET_ORDER, order);
                        self.$toast.success(`File was published successfully.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error({ message: `Error publishing file.` });
                        return error;
                    });
            },

            viewInR2c() {
                const self = this;
                let fromRoute = self.$route.name;
                console.log(fromRoute);
                let apiPromise = self.$api.Ready2CloseChangeTrackingApi.getR2cPortalUrl(self.order.ordersID, fromRoute);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        window.open(result, '_blank');
                        return result;
                    }).catch(error => {
                        self.$toast.error({ message: `Error getting portal URL` });
                        return error;
                    });
            },

            unpublishFromR2C() {
                const self = this;

                let apiPromise = self.$api.Ready2CloseChangeTrackingApi.unpublishOrder(self.order.ordersID);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        let order = _.clone(self.order);
                        order.isReady2Close = false;
                        self.$store.commit(ORDER_MUTATIONS.SET_ORDER, order);
                        self.$toast.success(`File was unpublished successfully.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error({ message: `Error unpublishing file.` });
                        return error;
                    });
            },

            downloadR2CActivity() {
                const self = this;

                let apiPromise = self.$api.Ready2CloseChangeTrackingApi.downloadR2CActivity(self.order.ordersID);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success(`Activity report was generated successfully.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error({ message: `Error generating activity report.` });
                        return error;
                    });
            },

            pushToR2C() {
                const self = this;

                let apiPromise = self.$api.Ready2CloseChangeTrackingApi.pushToR2C(self.order.ordersID);
                return self.$rqBusy.wait(apiPromise)
                    .then(result => {
                        self.$toast.success(`File was pushed successfully.`);
                        return result;
                    }).catch(error => {
                        self.$toast.error({ message: `Error pushing file.` });
                        return error;
                    });
            }

        }
    };

</script>