<template>
    <div class="p-4 text-center" v-if="showLogin">
        Please Login to the new window that was just launched, then click Retry.<br>
        <button
            type="button"
            class="btn btn-theme mt-3"
            variant="theme"
            @click="onRetryClick">
            Retry
        </button>
    </div>
    <div class="smkt-ezjacket-iframe-container" v-if="showIframe">
        <iframe name="smkt-ezjacket-iframe" id="smkt-ezjacket-iframe" :src="ezJacketUrl" class="smkt-ezjacket-iframe" seamless></iframe>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { EzJacketOrder } from '@order-entry/models';
import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

export default {
    name: "SmktEzJacket",
    props: {
    },
    data: function() {
        return {
            ezJacketOrder: null,
            showIframe: false,
            showLogin: false,
            ezJacketUrl: "",
            isSubmitted: false
        };
    },
    computed: {
        ...mapState({
            orderId: state => state.orders.orderId,
            user: state => state.authentication.session.user
        }),
    },
    watch: {
        orderId:{
            handler: function(newValue, oldValue) {
                if(!_.isEqual(newValue, oldValue)) {
                    this.init();
                }
            },
        }
    },
    beforeRouteLeave(to, from, next) {
        const self = this;
        if (self.isSubmitted){
            next();
            return;
        } else {
            let onYes = () => {
                self.isSubmitted = true;
                self.$events.$emit("refresh-jackets", false);
                next();
                return true;
            };
            let onNo = () =>{
                return true;
            };
            let msg = `You haven't submitted the jacket, are you sure you want to leave this tab?`;
            self.$dialog.confirm("Leave or Stay", msg, onYes, onNo, { cancelTitle: 'No', okTitle: 'Yes'});
        }
    },
    beforeUnmount () {
        window.document.removeEventListener("ezJacketSubmit", this.onSubmitOrderSuccess);
        window.document.removeEventListener("ezJacketSubmitError", this.onSubmitOrderError);
        window.document.removeEventListener("ezJacketError", this.onError);
    },
    created() {
        this.init();
    },
    mounted() {
        window.document.addEventListener("ezJacketSubmit", this.onSubmitOrderSuccess);
        window.document.addEventListener("ezJacketSubmitError", this.onSubmitOrderError);
        window.document.addEventListener("ezJacketError", this.onError);
    },
    methods:{
        fetchLookupData() {
            const self = this;
            let apiPromise = self.$api.SmktEzJacketApi.getEzJacketData(self.orderId);
            return self.$rqBusy.wait(apiPromise);
        },

        addOrder(id) {
            const self = this;
            let apiPromise = self.$api.SmktEzJacketApi.addOrder(self.orderId, id);
            return self.$rqBusy.wait(apiPromise);
        },

        onError(e) {
            const self = this;
            console.log(e);
            let loginError = _.get(e, "detail.error.message", null);
            if (loginError === "User is not authenticated.") {
                self.showLogin = true;
                self.isSubmitted = true;
            } else {
                let errorMessage = _.get(e, "detail.error", null) || _.get(e, "detail.error.errorDetails.errors[0].message");
                console.log(errorMessage);
                if (errorMessage != 'PoliciesFragment') {
                    self.isSubmitted = true;
                    self.$toast.error(`Failed to create Policy Jacket: ${errorMessage}`);
                    self.$events.$emit("refresh-jackets", true);
                }
            }
        },

        onSubmitOrderError(e) {
            const self = this;
            self.isSubmitted = true;
            let errorMessage = _.get(e, "detail.error.errorDetails.errors[0].message", null);
            self.$toast.error(`Failed to create Policy Jacket: ${errorMessage}`);
            self.$events.$emit("refresh-jackets", true);
        },

        onSubmitOrderSuccess(e) {
            const self = this;
            self.isSubmitted = true;
            let id = e?.detail?.order?.id || 0;
            if (id === 0) return;
            self.addOrder(id)
                .then(() => {
                        self.$events.$emit("refresh-jackets", true);
                    })
                    .catch(error => {
                        console.error(error);
                    });
        },

        onRetryClick() {
            this.isSubmitted = true;
            this.showLogin = false;
            this.showIframe = false;
            this.init();
            document.getElementById('smkt-ezjacket-iframe').location.reload();
        },

        init() {
            const self = this;
            self.fetchLookupData()
                .then(data => {
                    let ts = DateTimeHelper.now("MMddyyyy");
                    let order = new EzJacketOrder(data.order);
                    let user = `hzn-${self.user.emailAddress}`.toLowerCase();
                    localStorage.setItem("smkt:jwt", data.authToken);
                    localStorage.setItem("smkt:host", data.authHost);
                    localStorage.setItem("smkt:ezJacketOrder", JSON.stringify(order));
                    self.ezJacketUrl = `${window.location.origin}/smktEzJacket.html?ts=${ts}&user=${user}`;
                    self.showIframe = true;
                })
                .catch(error => {
                    console.error(error);
                });
        },
    }
}
</script>
<style lang="scss">
.smkt-ezjacket-iframe-container {
    flex: 1;
    overflow: hidden;
    background: #EFEFEF;
    height: 100%;
}
.smkt-ezjacket-iframe {
    position: relative;
    overflow: hidden;
    height: calc(100vh);
    width: 100%;
    border: none;
    margin: 0;
}
</style>