<template>
    <div class="rq-container content-wrapper">
        <div class="row mb-2">
            <div class="col col-12 form-group" v-html="policyMsg">
            </div>
        </div>
        <div class="row">
            <div class="col col-12 form-group">
                <label for="drp_payment_method">Payment Method</label>
                <dx-select-box
                    :input-attr="{ automation_id: 'drp_payment_method', id: 'drp_payment_method' }"
                    :items="paymentMethods"
                    value-expr="id"
                    display-expr="name"
                    v-model="paymentMethod"
                    :search-enabled="false"
                />
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "SmktEzJacketRemitDialog",
    props: {
        policyMsg: { type: String, default: "" },
        paymentMethods: { type: Array, default: () => [] }
    },
    data: function() {
        return {
            paymentMethod: 0
        };
    },
}
</script>