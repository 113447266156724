<template>
    <div class="rq-container content-wrapper">
        <div class="row mb-2">
            <div class="col col-12 form-group" v-html="policyMsg">
            </div>
        </div>
        <div class="row">
            <div class="col col-12 form-group">
                <label for="txt_void_reason">Void Reason</label>
                <textarea id="txt_void_reason" automation_id="txt_void_reason" maxlength="250" rows="2" v-model="voidReason" class="form-control"></textarea>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "SmktEzJacketVoidDialog",
    props: {
        policyMsg: { type: String, default: "" }
    },
    data: function() {
        return {
            voidReason: ""
        };
    },
}
</script>