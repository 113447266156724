const CSSMain = () => import(/* webpackChunkName: "commercial-settlement-statement" */"./CSSMain.vue");
import { ViewActions } from "@/router/actions/commercial-settlement-statement";
import { OrderNoteCategory } from "@/shared/models/enums";

export default {
    name: "css-main",
    path: "css-main",
    component: CSSMain,
    meta: {
        entity: "CSS Main",
        label: "CSS Main",
        actions: ViewActions.MAIN,
        noteCategory: OrderNoteCategory.CSS,
        accessKey: "CommercialSettlementStatement_ScreenAccess",
        skipSaveOnLeave: false,
        include: true,
        templateCategory: "cs"
    }
};
