<template>
<div class="rq-container">
    <rq-page-section
        title="Print Settlement Statement"
        headerSize="sm"
        section-group="settlement-dashboard"
        collapsible>
        <div class="row">
            <div v-for="docTemplate in filteredDocTemplates"
                class="col-auto"
                :key="docTemplate.column"
                :id="`div_document_${docTemplate.column}`"
                v-rq-tooltip="{ title: docTemplate.isConfigured ? '' : notConfiguredTooltip }">
                <b-btn
                    :automation_id="getAutoId(docTemplate.name)"
                    variant="link"
                    class="btn-theme"
                    :disabled="!docTemplate.isConfigured"
                    @click="launchHudDocViewer(docTemplate)">{{docTemplate.name}}
                </b-btn>
            </div>
        </div>
    </rq-page-section>
</div>
</template>

<script>
    import { mapState } from "vuex";
    import { DOCUMENT_ACTIONS } from "@/store/actions";
    import { DOCUMENT_MUTATIONS } from "@/store/mutations";
    import { SettlementDocTemplateDto } from "./models";
    import { SETTLEMENT_TYPE } from '@settlement/models';
    import useDocumentGeneration from "@/shared/composables/useDocumentGeneration";

    export default {
        name:"SettlementDocSection",
        props: {
            loanId: { type: Number,  default: 0 },
            isHasRateAlerts: { type: Boolean, default: false },
        },
        setup() {
            const notConfiguredTooltip = "Default document not set in config.<br/>Unable to print document.";
            const confirmMessage = "The Sales Price or Loan Amount has been updated since the Premiums were updated, therefore the Premiums might be incorrect.  Continue?";
            const getAutoId = name => `btn_generate_${_.toLower(_.snakeCase(name))}`;
            const { generateDocuments } = useDocumentGeneration();
            return {
                notConfiguredTooltip,
                notConfiguredToast: _.replace(notConfiguredTooltip, "<br/>", ""),
                confirmMessage,
                getAutoId,
                generateDocuments
            };
        },
        computed: {
            ...mapState({
                settlementStatementDocuments: state => state.system.settlementStatementDocuments,
                orderSummary: state => state.orders.orderSummary,
                selectedView: state => _.getNumber(state, "orders.orderSummary.settlementStatementType", SETTLEMENT_TYPE.CDF),
                isWithOutSeller: state => _.getBool(state, "orders.orderSummary.isWithOutSeller"),
                includeAlta: state => _.getBool(state, "orders.orderSummary.includeAlta"),
                isP2AB: state => _.getBool(state, "orders.orderSummary.useTemplatePage2A2B"),
                loans: state => state.orders.loans || [],
                fileNumber: state => state.orders.orderSummary.fileNumber,
            }),
            docTemplates() { return _.map(this.settlementStatementDocuments, ssd => SettlementDocTemplateDto.createConfigured(ssd)); },
            defaultLoan() { return _.find(this.loans, l => l.loanID === this.loanId); },
            isCdf() { return this.selectedView === SETTLEMENT_TYPE.CDF; },
            isHud() { return this.isHud1974 || this.isHud2010; },
            isHud1974() { return this.selectedView === SETTLEMENT_TYPE.HUD_1974; },
            isHud2010() { return this.selectedView === SETTLEMENT_TYPE.HUD_2010; },
            filteredDocTemplates() {

                // CDF Docs - filtering with variety of combinations related to ALTA and/or P2A2B and/or with/without seller
                if (this.isCdf) {
                    if (this.includeAlta) {
                        // Special conditions to filter out specific Cdf with Alta docs specifically since there is a common document shared for alta
                        // between with and with-out seller.
                        let cdfAltaTemplates = this.isWithOutSeller
                            ? _.filter(this.docTemplates, i => (i.settlementType === this.selectedView           // captures cdf/p2a2b and alta without-seller docs
                                && i.isWithOutSeller && i.isP2AB === this.isP2AB)
                                || i.isAltaWithOutSeller)
                            : _.filter(this.docTemplates, i => i.settlementType === this.selectedView           // captures cdf/p2a2b and alta with-seller docs
                                && (i.isP2AB === this.isP2AB || i.includeAlta)
                                && (i.isWithOutSeller === false || i.isAltaWithOutSeller && i.isWithOutSeller));

                        // Further filter out mutually printing ALTA combined or ALTA cash documents depending if Loan Amount is present
                        cdfAltaTemplates = this.defaultLoan.amount > 0
                            ? _.filter(cdfAltaTemplates, i => !i.isAltaCash)
                            : _.filter(cdfAltaTemplates, i => !i.isAltaCombined)

                        return _.sortBy(cdfAltaTemplates, 'sequence');
                    }

                    // Default filter of CDF documents in general
                    let relevantTemplates = _.filter(this.docTemplates, i => i.settlementType === this.selectedView
                        && i.isP2AB === this.isP2AB
                        && i.isWithOutSeller === this.isWithOutSeller
                        && i.includeAlta === this.includeAlta);
                    return _.sortBy(relevantTemplates, 'sequence');
                }

                let isCdfAlta = this.isCdf && this.includeAlta;
                // HUD Docs - general filter for HUD type documents
                if (this.isHud) {
                    // default filter of documents
                    let relevantTemplates = _.filter(this.docTemplates, i => i.settlementType === this.selectedView
                        && i.isWithOutSeller === this.isWithOutSeller
                        && i.includeAlta === isCdfAlta);
                    return _.sortBy(relevantTemplates, 'sequence');
                }

                return [];
            }
        },
        methods: {
            async launchHudDocViewer(doc) {
                const self = this;
                if(!doc.isConfigured) {
                    self.$toast.warn(self.notConfiguredToast);
                    return;
                }

                let showDocViewer = async function () {
                    let storePromise = self.$store.dispatch(DOCUMENT_ACTIONS.ADD_DOC_TO_ORDER, { documentTemplateID: doc.documentTemplateID, clearState: true });
                    await self.$rqBusy.wait(storePromise, {topLevel: true});

                    // Set LoanID for document generation
                    self.$store.commit(DOCUMENT_MUTATIONS.SET_LOAN_ID, self.loanId);
                    await self.generateDocuments({skipQueueUpdate: true});
                    return true;
                }

                if (self.isHasRateAlerts) {
                    self.$dialog.confirm("Confirm", self.confirmMessage, showDocViewer, null, { cancelTitle: 'NO', okTitle: 'YES'});
                }
                else {
                    showDocViewer();
                }               
            }
        }
    };
</script>