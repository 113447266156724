<template>
    <div :class="classAttr">
        <rq-grid-action-bar
            class="rq-table-selection"
            :actions="actionList"
            :selected-items="selectedItems"
            :read-only="readOnly"
            @action-click="emitActionEvent"
        />
        <rq-scroll-container
            ref="scrollContainer"
            ps-class-attr="premium-scroll-area"
            perfect-scrollbar
            ps-default-x
            always-visible
            always-wide>
            <table class="rq-table rq-table-bordered">
                <thead>
                    <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th v-if="hasReissueCredit || hasEndorsements"></th>
                        <th v-if="!premium.noTax" colspan="3" class="rq-col-bottom-border">Tax</th>
                        <th colspan="2" class="rq-col-bottom-border">Buyer Pays</th>
                        <th colspan="2" class="rq-col-bottom-border">Seller Pays</th>
                        <th colspan="2" class="rq-col-bottom-border">UW Split</th>
                        <th v-if="is3PageHud || isCdf"></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    <tr>
                        <th class="rq-col-selection"></th>
                        <th class="col-description">Description</th>
                        <th class="col-payee">Payee</th>
                        <th class="col-breakout" v-if="hasReissueCredit || hasEndorsements">Breakout</th>
                        <th class="col-amount">Amount</th>
                        <template v-if="!premium.noTax">
                            <th class="col-percent">Rate</th>
                            <th class="col-amount">Amount</th>
                            <th class="col-amount">Total</th>
                        </template>
                        <th class="col-percent">%</th>
                        <th class="col-amount">Amount</th>
                        <th class="col-percent">%</th>
                        <th class="col-amount">Amount</th>
                        <th class="col-percent">%</th>
                        <th class="col-amount">Amount</th>
                        <th v-if="is3PageHud" class="col-cdf-section">Included on GFE</th>
                        <th v-if="isCdf" class="col-cdf-section">CDF Section</th>
                        <th class="col-amount">POC Amount</th>
                        <th class="col-poc-who">POC Whom</th>
                        <th class="col-net-fund">Net Fund</th>
                        <th class="col-addition-remit-date">Additional Remit Date</th>
                    </tr>
                </thead>
                <tbody>
                    <!-- CDF Simulataneous Premium line ///
                        FYI: underwriterSplit remains unchanges from basic to pull data from premium versus cdfPremium
                    -->
                    <PremiumLineItem
                        v-if="isCdfSimultaneous"
                        v-show="isCdfView"
                        ref="cdfPremium"
                        id="cdfPremium"
                        class="cdf-line-item alt-row"
                        premium-row
                        show-tax
                        :label="cdfPremium.premiumNameDisplay"
                        :hideBreakoutColumn="hideBreakoutColumn"
                        :hideTaxColumn="premium.noTax"
                        :isSimultaneous="premium.simultaneousCalculation"
                        :isLoanHolder="premium.isLoanHolder"
                        :isCdfView="true"
                        v-model:amount="cdfPremium.premiumAmount"
                        :defaultAmount="cdfPremium.calculatedPremiumAmount"
                        :total="cdfPremium.taxTotal"
                        :taxRate="cdfPremium.taxRate"
                        :taxAmount="cdfPremium.taxAmount"
                        :underwriterSplitPercent="cdfPremium.underwriterSplitPercent"
                        v-model:underwriterSplit="cdfPremium.underwriterSplit"
                        v-model:overrideUnderwriter="cdfPremium.overrideUnderwriter"
                        :defaultUnderwriter="cdfPremium.calculatedUnderwriterSplit"
                        v-model:buyerSellerAmountApplyOverride="cdfPremium.buyerSellerAmountApplyOverride"
                        v-model:buyerPercent="cdfPremium.buyerPayPctForLoan"
                        v-model:buyerAmount="cdfPremium.buyerToPay"
                        v-model:sellerPercent="cdfPremium.sellerPayPctForOwner"
                        v-model:sellerAmount="cdfPremium.sellerToPay"
                        v-model:applyOverride="cdfPremium.applyOverride"
                        v-model:payeeCompanyID="premium.payeeCompanyID"
                        v-model:payeeCompanyName="premium.payeeCompanyName"
                        v-model:payeeContactID="premium.payeeContactID"
                        v-model:cdfLine="cdfPremium.cdfLine"
                        v-model:sectionID="premium.sectionID"
                        v-model:non-gfe="cdfPremium.nonGFE"
                        v-model:addtlRemitDate="cdfPremium.addtlRemitDate"
                        v-model:pocAmount="premium.poC_BasicAmount"
                        v-model:pocWhom="premium.poC_Basic_Who"
                        v-model:netFund="premium.poC_Basic_NF"
                        :read-only="readOnly"
                        v-model:is-selected="isSelected.cdfPremium"
                        @values-changed="onCalculatedValuesChanged"
                    />
                    <!---->

                    <!--Basic/Simultaneous Premium-->
                    <PremiumLineItem
                        v-show="!isCdfView"
                        id="basicPremium"
                        ref="basicPremium"
                        class="alt-row"
                        premium-row
                        show-tax
                        underwriter-editable
                        :label="premium.premiumNameDisplay"
                        :hideBreakoutColumn="hideBreakoutColumn"
                        :hideTaxColumn="premium.noTax"
                        :isSimultaneous="premium.simultaneousCalculation"
                        :isLoanHolder="premium.isLoanHolder"
                        v-model:amount="premium.premiumAmount"
                        :defaultAmount="premium.calculatedPremiumAmount"
                        :total="premium.taxTotal"
                        :taxRate="premium.taxRate"
                        :taxAmount="premium.taxAmount"
                        :underwriterSplitPercent="premium.underwriterSplitPercent"
                        v-model:underwriterSplit="premium.underwriterSplit"
                        v-model:overrideUnderwriter="premium.overrideUnderwriter"
                        v-model:buyerSellerAmountApplyOverride="premium.buyerSellerAmountApplyOverride"
                        :defaultUnderwriter="premium.calculatedUnderwriterSplit"
                        v-model:buyerPercent="premium.buyerPayPctForLoan"
                        v-model:buyerAmount="premium.buyerToPay"
                        v-model:sellerPercent="premium.sellerPayPctForOwner"
                        v-model:sellerAmount="premium.sellerToPay"
                        v-model:applyOverride="premium.applyOverride"
                        v-model:payeeCompanyID="premium.payeeCompanyID"
                        v-model:payeeCompanyName="premium.payeeCompanyName"
                        v-model:payeeContactID="premium.payeeContactID"
                        v-model:cdfLine="premium.cdfLine"
                        v-model:sectionID="premium.sectionID"
                        v-model:non-gfe="premium.nonGFE"
                        v-model:addtlRemitDate="premium.addtlRemitDate"
                        v-model:pocAmount="premium.poC_BasicAmount"
                        v-model:pocWhom="premium.poC_Basic_Who"
                        v-model:netFund="premium.poC_Basic_NF"
                        :read-only="readOnly"
                        v-model:is-selected="isSelected.basicPremium"
                        @values-changed="onCalculatedValuesChanged"
                    />
                    <!---->

                    <!--Reissue Credit-->
                    <PremiumLineItem
                        v-if="hasReissueCredit"
                        v-show="!isCdfView"
                        id="reissueCredit"
                        ref="reissueCredit"
                        label="Reissue Credit"
                        reissue-credit-row
                        :hideTaxColumn="premium.noTax"
                        :isLoanHolder="premium.isLoanHolder"
                        v-model:amount="premium.creditTotal"
                        :defaultAmount="premium.calculatedCreditTotal"
                        :total="premium.creditTotal"
                        v-model:buyerPercent="premium.buyerPayPctForLoan_Ric"
                        v-model:buyerAmount="premium.buyerToPay_Ric"
                        v-model:sellerPercent="premium.sellerPayPctForOwner_Ric"
                        v-model:sellerAmount="premium.sellerToPay_Ric"
                        v-model:applyOverride="premium.applyOverride_Ric"
                        v-model:buyerSellerAmountApplyOverride="premium.buyerSellerAmountApplyOverride_Ric"
                        v-model:pocAmount="premium.poC_BasicAmount"
                        v-model:pocWhom="premium.poC_Basic_Who"
                        v-model:netFund="premium.poC_Basic_NF"
                        :read-only="readOnly"
                        v-model:is-selected="isSelected.reissueCredit"
                        @values-changed="onCalculatedReissueValuesChanged"
                    />
                    <!--Cdf Reissue Credit-->
                    <PremiumLineItem
                        v-if="hasReissueCredit && isCdf && isCdfSimultaneous"
                        v-show="isCdfView"
                        id="reissueCredit"
                        ref="reissueCredit"
                        label="Reissue Credit"
                        reissue-credit-row
                        :hideTaxColumn="premium.noTax"
                        :isLoanHolder="premium.isLoanHolder"
                        v-model:amount="cdfPremium.creditTotal"
                        :defaultAmount="cdfPremium.calculatedCreditTotal"
                        :total="cdfPremium.creditTotal"
                        v-model:buyerPercent="cdfPremium.buyerPayPctForLoan_Ric"
                        v-model:buyerAmount="cdfPremium.buyerToPay_Ric"
                        v-model:sellerPercent="cdfPremium.sellerPayPctForOwner_Ric"
                        v-model:sellerAmount="cdfPremium.sellerToPay_Ric"
                        v-model:applyOverride="cdfPremium.applyOverride_Ric"
                        v-model:buyerSellerAmountApplyOverride="cdfPremium.buyerSellerAmountApplyOverride_Ric"
                        v-model:pocAmount="premium.poC_BasicAmount"
                        v-model:pocWhom="premium.poC_Basic_Who"
                        v-model:netFund="premium.poC_Basic_NF"
                        :read-only="readOnly"
                        v-model:is-selected="isSelected.reissueCredit"
                        @values-changed="onCalculatedReissueValuesChanged"
                    />
                    <!---->

                    <!--Associated Premium-->
                    <PremiumLineItem
                        v-if="hasAssociated"
                        :class="{ 'alt-row': isAltRow(2) }"
                        id="associatedPremium"
                        ref="associatedPremium"
                        label="Associated Premium"
                        show-tax
                        :hideBreakoutColumn="hideBreakoutColumn"
                        :hideTaxColumn="premium.noTax"
                        v-model:amount="premium.premiumAmount_Assoc"
                        :defaultAmount="premium.calculatedPremiumAmount_Assoc"
                        :total="premium.taxTotalAssociated"
                        :taxRate="premium.taxRateAssociated"
                        :taxAmount="premium.taxAmountAssociated"
                        v-model:buyerPercent="premium.buyerPayPctForLoan_Assoc"
                        v-model:buyerAmount="premium.buyerToPay_Assoc"
                        v-model:sellerPercent="premium.sellerPayPctForOwner_Assoc"
                        v-model:sellerAmount="premium.sellerToPay_Assoc"
                        v-model:applyOverride="premium.applyOverride_Assoc"
                        v-model:buyerSellerAmountApplyOverride="premium.buyerSellerAmountApplyOverride_Assoc"
                        v-model:pocAmount="premium.poC_BasicAmount"
                        v-model:pocWhom="premium.poC_Basic_Who"
                        v-model:netFund="premium.poC_Basic_NF"
                        :read-only="readOnly"
                        v-model:is-selected="isSelected.associatedPremium"
                        @values-changed="onCalculatedValuesChanged"
                    />
                    <!---->
                </tbody>
                <tfoot>
                    <tr class="total-row">
                        <th class="col-checkbox"></th>
                        <th class="col-description align-left">Premiums</th>
                        <th></th>
                        <th v-if="hasReissueCredit || hasEndorsements"></th>
                        <th :colspan="premium.noTax ? 1 : 4">{{premiumTotal}}</th>
                        <th></th>
                        <th>{{premiumBuyerTotal}}</th>
                        <th></th>
                        <th>{{premiumSellerTotal}}</th>
                        <th></th>
                        <th>{{premiumUnderwriterTotal}}</th>
                        <th v-if="is3PageHud || isCdf"></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                </tfoot>
            </table>
        </rq-scroll-container>
    </div>
</template>
<script>
    import { mapState } from "vuex";
    import { SettlementTypeOption } from "@settlement/models";
    import PremiumLineItem from "./PremiumLineItem";
    import GridAction from "@/shared/models/GridAction";

    const PREMIUM_TABLE_ACTION = {
        REVERT: "revert",
    }

    export default {
        name: "PremiumTable",
        components: { PremiumLineItem },
        props: {
            premium: { type: Object, default: () => ({}) },
            cdfPremium: { type: Object, default: () => ({}) },
            hasReissueCredit: { type: Boolean },
            hasEndorsements: { type: Boolean },
            isCdfView: { type: Boolean },
            isCdfSimultaneous: { type: Boolean, default: false },
            readOnly: { type: Boolean, default: false }
        },
        data: function() {
            return {
                isSelected: {
                    cdfPremium: false,
                    basicPremium: false,
                    reissueCredit: false,
                    associatedPremium: false
                }
            }
        },
        computed: {
            ...mapState({
                settlementStatementType: state => state.orders.orderSummary.settlementStatementType,
            }),
            cdfPremiumComponent() { return _.get(this, "$refs.cdfPremium", null); },
            premiumComponent() { return _.get(this, "$refs.basicPremium", null); },
            is3PageHud() { return this.settlementStatementType === SettlementTypeOption.HUD_2010; },
            isCdf() { return this.settlementStatementType === SettlementTypeOption.CDF; },
            classAttr() {
                return {
                    "rq-table-container": true,
                    "rq-grid-multi-select": true,
                    [`theme-${ _.get(this, "$route.meta.theme", "default")}`]: true
                };
            },
            hideBreakoutColumn() {
                return !this.hasReissueCredit && !this.hasEndorsements;
            },
            hasAssociated() {
                return !_.eq(_.parseNumber(this.premium.premiumAmount_Assoc, 0), 0);
            },
            selectionActions() {
                const self = this;
                return [
                    {
                        name: "revert",
                        text: "Revert",
                        eventName: "revert",
                        allowMultiSelection: true,
                        tooltip: "Revert Overrides",
                        disabled(e) {
                            if (self.readOnly || _.isEmpty(e.data)) return true;
                            return !_.some(e.data, item => item.buyerSellerAmountApplyOverride || item.applyOverride || (!self.isCdfView && item.overrideUnderwriter) || (self.isSelected.reissueCredit && (item.applyOverride_Ric || item.buyerSellerAmountApplyOverride_Ric)));
                        }
                    }
                ];
            },
            selectedPremiumView() {
                if (this.isCdfView)
                    return this.cdfPremium;
                else
                    return this.premium;
            },
            premiumTotal() {
                let premium = this.selectedPremiumView;
                return this.formatMoney(premium.taxTotal + premium.creditTotal + premium.taxTotalAssociated);
            },
            premiumBuyerTotal() {
                let premium = this.selectedPremiumView;
                return this.formatMoney(premium.buyerToPay + premium.buyerToPay_Ric + premium.buyerToPay_Assoc);
            },
            premiumSellerTotal() {
                let premium = this.selectedPremiumView;
                return this.formatMoney(premium.sellerToPay + premium.sellerToPay_Ric + premium.sellerToPay_Assoc);
            },
            premiumUnderwriterTotal() {
                // underwriter total always displays the Premium value
                let premium = this.premium;
                return this.formatMoney(premium.underwriterSplit + premium.underwriterSplit_Assoc);
            },
            selectedItems() { return _.some(this.isSelected) ? [this.selectedPremiumView] : []; }
        },
        created() {
            this.initializeTableActions();
        },
        methods: {
            initializeTableActions() {
                let mappedActions = _.map(this.selectionActions, a => new GridAction(a));
                this.toolbarActionList = _.filter(mappedActions, a => a.isToolbarAction);
                this.toolbarSettingsList = _.filter(mappedActions, a => a.isToolbarSetting);
                this.actionList = _.filter(mappedActions, a => !a.isToolbarAction && !a.isToolbarSetting);
            },
            isAltRow(rowNum) {
                let result = false;
                let indexVisiblity = [
                    true,
                    this.hasReissueCredit,
                    this.hasAssociated,
                    this.hasEndorsements,
                ];
                for(let i=0; i<5; i++){
                    if(indexVisiblity[i]) result = !result;
                    if(rowNum === i) return result;
                }
            },
            formatMoney(v) { return accounting.formatMoney(_.parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s0.00" } }); },
            onOverrideReverted() {
                this.$emit("reverted");
            },
            onCalculatedValuesChanged(args) {
                //RQO-19740 - Syncing overrides between basic premium and cdf premium for buyer and seller fields are affected
                // args: includes provided fields that are being updated
                if (args) {
                    if (this.isCdfView) {
                        this.syncBasicPremiumFields(args);
                    }
                    else {
                        this.syncCdfPremiumFields(args);
                    }
                }
                this.$emit("changed");
            },
            onCalculatedReissueValuesChanged(args) {
                if (args) {
                    if (this.isCdfView) {
                        this.syncBasicReiussueCreditFields(args);
                    }
                    else {
                        this.syncCdfReiussueCreditFields(args);
                    }
                }
                this.$emit("changed");
            },
            syncCdfPremiumFields(fieldNames) {
                //RQO-19740 - Syncing overrides between basic premium and cdf premium for buyer and seller fields
                // Note: we want to match the percentage between both type of premiums and not necessarily the amounts
                // So overriding a buyer/seller amount will effectively auto calculate a buyer/seller percentage for
                // the active premium view. That percentage is what we want to sync to the other premium type below
                // syncPremiumFields we care about - ["buyerPercent", "buyerAmount", "sellerPercent" , "sellerAmount"];
                if (_.includes(fieldNames, "buyerPercent") || _.includes(fieldNames, "buyerAmount")) {
                    this.cdfPremium.buyerSellerAmountApplyOverride = this.premium.buyerSellerAmountApplyOverride;
                    this.cdfPremium.buyerPayPctForLoan = this.premium.buyerPayPctForLoan;
                }
                if (_.includes(fieldNames, "sellerPercent") || _.includes(fieldNames, "sellerAmount")) {
                    this.cdfPremium.buyerSellerAmountApplyOverride = this.premium.buyerSellerAmountApplyOverride;
                    this.cdfPremium.sellerPayPctForOwner = this.premium.sellerPayPctForOwner;
                }
            },
            syncBasicPremiumFields(fieldNames) {
                //RQO-19740 - Syncing overrides between basic premium and cdf premium for buyer and seller fields
                // Note: we want to match the percentage between both type of premiums and not necessarily the amounts
                // So overriding a buyer/seller amount will effectively auto calculate a buyer/seller percentage for
                // the active premium view. That percentage is what we want to sync to the other premium type below
                if (_.includes(fieldNames, "buyerPercent") || _.includes(fieldNames, "buyerAmount")) {
                    this.premium.buyerSellerAmountApplyOverride = this.cdfPremium.buyerSellerAmountApplyOverride;
                    this.premium.buyerPayPctForLoan = this.cdfPremium.buyerPayPctForLoan;
                }
                if (_.includes(fieldNames, "sellerPercent") || _.includes(fieldNames, "sellerAmount")) {
                    this.premium.buyerSellerAmountApplyOverride = this.cdfPremium.buyerSellerAmountApplyOverride;
                    this.premium.sellerPayPctForOwner = this.cdfPremium.sellerPayPctForOwner;
                }
            },
            syncCdfReiussueCreditFields(fieldNames) {
                if (_.includes(fieldNames, "buyerPercent") || _.includes(fieldNames, "buyerAmount")) {
                    this.cdfPremium.buyerSellerAmountApplyOverride_Ric = this.premium.buyerSellerAmountApplyOverride_Ric;
                    this.cdfPremium.buyerPayPctForLoan_Ric = this.premium.buyerPayPctForLoan_Ric;
                }
                if (_.includes(fieldNames, "sellerPercent") || _.includes(fieldNames, "sellerAmount")) {
                    this.cdfPremium.buyerSellerAmountApplyOverride_Ric = this.premium.buyerSellerAmountApplyOverride_Ric;
                    this.cdfPremium.sellerPayPctForOwner_Ric = this.premium.sellerPayPctForOwner_Ric;
                }
            },
            syncBasicReiussueCreditFields(fieldNames) {
                if (_.includes(fieldNames, "buyerPercent") || _.includes(fieldNames, "buyerAmount")) {
                    this.premium.buyerSellerAmountApplyOverride_Ric = this.cdfPremium.buyerSellerAmountApplyOverride_Ric;
                    this.premium.buyerPayPctForLoan_Ric = this.cdfPremium.buyerPayPctForLoan_Ric;
                    this.premium.buyerToPay_Ric = this.cdfPremium.buyerToPay_Ric
                }
                if (_.includes(fieldNames, "sellerPercent") || _.includes(fieldNames, "sellerAmount")) {
                    this.premium.buyerSellerAmountApplyOverride_Ric = this.cdfPremium.buyerSellerAmountApplyOverride_Ric;
                    this.premium.sellerPayPctForOwner_Ric = this.cdfPremium.sellerPayPctForOwner_Ric;
                    this.premium.sellerToPay_Ric = this.cdfPremium.sellerToPay_Ric;
                }
            },
            revertOverrides() {
                const self = this;
                _.each(self.isSelected, (v,k) => {
                    if(!v || !_.has(self.$refs, k)) return;
                    self.$refs[k].revertOverride();

                    //RQO-19740 - We are syncing overrides for buyer/seller fields between cdfPremium and basicPremium
                    // and since overrides are tied together we want the revert to sync as well
                    if (k === "basicPremium" && _.has(self.$refs, "cdfPremium")) {
                        self.$refs["cdfPremium"].revertOverride();
                    }

                    if (k === "cdfPremium" && _.has(self.$refs, "basicPremium")) {
                        self.$refs["basicPremium"].revertOverride();
                    }
                    if (k === "reissueCredit" && _.has(self.$refs, "cdfPremium")) {
                        self.syncBasicReiussueCreditFields(["buyerPercent", "sellerPercent"]);
                    }
                });
                self.clearItemSelections();
            },
            emitActionEvent(action) {
                if(action.name === PREMIUM_TABLE_ACTION.REVERT) {
                    this.revertOverrides();
                    this.$emit("reverted");
                }
            },
            clearItemSelections() {
                for(let prop in this.isSelected) {
                    this.isSelected[prop] = false;
                }
            },
            refreshScrollbar() {
                this.$nextTick(() => {
                    _.invoke(this, "$refs.scrollContainer.update");
                });
            },
        }
    }
</script>