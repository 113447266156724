<template>
    <div class="rq-container">
        <rq-banner
            variant="error"
            message="Please correct the highlighted errors on screen to continue."
            icon="fas fa-exclamation-triangle"
            :visible="showBanner"
        />
        <div class="row my-3">
            <div class="col-auto">
                <b-btn automation_id="btn_copy_existing_file"
                    variant="theme"
                    :disabled="!canUseOrderTemplate"
                    @click="onCopyExistingClick">Copy Existing File</b-btn>
            </div>
            <div class="col-auto ms-auto" v-rq-tooltip.hover.top :title="fileNumberProcessingTooltip">
                <b-btn automation_id="btn_calculate_next_number"
                    variant="theme"
                    :disabled="calculateNextNumberDisabled"
                    @click="onCalculateNextNumber">Calculate Next Number</b-btn>
            </div>
        </div>

        <!--GFNo-->
        <file-number-input
            v-show="!isFileSequenceInputActive"
            ref="fileNumberInput"
            automation_id="txt_new_file_number"
            :class="{ 'mb-0': showUpdateFileSequence, 'overridden': isFileNumberOverridden}"
            validation-mode="unique"
            v-model:has-error="isInvalidFileNumber"
            v-model:is-processing="isFileNumberProcessing"
            :read-only="isFileNumberReadOnly"
            @valueChanged="onValueChanged"
            v-model="orderModel.gfNo"
            show-clear-button
            required>
            <template #label>
                <div class="row">
                    <div class="col-auto">
                        <label>New File Number</label>
                    </div>
                    <div v-show="isFileNumberOverridden" :class="{'col-auto form-group-action ms-auto': true}">
                        <b-btn variant="link" class="btn-theme" @click="onRevertFileNumber">Revert</b-btn>
                    </div>
                </div>
            </template>
            <div v-show="showUpdateFileSequence" class="row">
                <div class="col-auto ms-auto">
                    <b-btn automation_id="btn_update_file_numbering" variant="link" class="btn-theme p-0" @click="setFileSequenceInputActive">Update file numbering </b-btn>
                </div>
            </div>
        </file-number-input>

        <div v-show="isFileSequenceInputActive" class="row">
            <div class="form-group col-4 mb-0">
                <label for="txt_new_file_number">Prefix</label>
                <input class="form-control" v-model="fileNumberPool.prefix" disabled />
            </div>
            <div class="form-group col-4 mb-0">
                <label for="txt_new_file_number">Next File Number</label>
                <rq-input-number id="txt_next_file_number" :commas="false" v-model="fileNumberPool.nextFileNumber" />
            </div>
            <div class="form-group col-4 mb-0">
                <label for="txt_new_file_number">Suffix</label>
                <input class="form-control" v-model="fileNumberPool.suffix" disabled />
            </div>
            <div class="col-auto ms-auto mb-0">
                <b-btn automation_id="btn_update_file_number_cancel" variant="link" class="btn-theme p-0" @click="onUpdateFileSequenceCancel">Cancel</b-btn>
                <span class="text-muted mx-2">|</span>
                <b-btn automation_id="btn_update_file_nunbmer_ok" variant="link" class="btn-theme p-0" @click="onUpdateFileSequenceOk">OK</b-btn>
            </div>
        </div>

        <!--region-->
        <div class="form-group form-required" :class="{'has-error' : isInvalidRegion }">
            <label>Region</label>
            <dx-select-box
                :elementAttr="{ automation_id: 'drp_new_region' }"
                :items="regionsLookup"
                displayExpr="displayName"
                valueExpr="regionID"
                :search-enabled="false"
                v-model="orderModel.regionID"
                @valueChanged="onRegionChanged"
            />
        </div>

        <!--branch-->
        <div class="form-group form-required" :class="{'has-error' : isInvalidBranch }">
            <label>Branch</label>
            <dx-select-box
                :elementAttr="{ automation_id: 'drp_new_branch' }"
                :items="lookups.branches"
                displayExpr="name"
                valueExpr="id"
                :search-enabled="false"
                v-model="orderModel.branchID"
                @valueChanged="onBranchChanged"
            />
        </div>

        <!--title unit-->
        <div class="form-group" v-show="hasTitleUnits">
            <label>Title Unit</label>
            <dx-select-box
                :elementAttr="{ automation_id: 'drp_new_title_unit' }"
                :items="filteredTU"
                displayExpr="name"
                valueExpr="id"
                :search-enabled="true"
                :show-clear-button="true"
                v-model="orderModel.titleUnitID"
                @valueChanged="onTitleUnitChanged"
            />
        </div>

        <!--escrow unit-->
        <div class="form-group" v-show="hasEscrowUnits">
            <label>Escrow Unit</label>
            <dx-select-box
                :elementAttr="{ automation_id: 'drp_new_escrow_unit' }"
                :items="filteredEU"
                displayExpr="name"
                valueExpr="id"
                :search-enabled="true"
                :show-clear-button="true"
                v-model="orderModel.escrowUnitID"
                @valueChanged="onEscrowUnitChanged"
            />
        </div>

        <!--process template-->
        <div class="form-group">
            <label for="drp_new_process_template">Process Template</label>
            <dx-select-box
                :elementAttr="{ automation_id: 'drp_new_process_template' }"
                :items="filteredProcessTemplates"
                displayExpr="name"
                valueExpr="id"
                :search-enabled="true"
                :show-clear-button="true"
                v-model="orderModel.workflowProcessTemplateID"
                @valueChanged="onProcessTemplateChanged"
            />
        </div>

        <!--settlement type-->
        <div class="form-group form-required" :class="{'has-error' : isInvalidSettlementType }">
            <label for="drp_settlement_type">Settlement Type</label>
            <dx-select-box
                :input-attr="{ id: 'drp_settlement_type', automation_id: 'drp_settlement_type' }"
                class="form-control"
                :items="settlementTypes"
                value-expr="id"
                display-expr="name"
                :search-enabled="true"
                :show-clear-button="true"
                :disabled="isSettlementTypeDisabled"
                v-model="orderModel.settlementStatementType"
            />
        </div>

    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import { NewFileDialogModel } from "../../../shared/models/models";
    import { ORDER_ACTIONS } from "../../../store/actions";
    import SelectExistingOrder from "../shared/components/SelectExistingOrder";
    import FileNumberInput from "@/shared/components/rq/FileNumberInput";
    import { SETTLEMENT_TYPE } from '@settlement/models';

    const valueDependency = {
        EU: 'EU',
        TU: 'TU'
    }

    export default {
        name: "NewOrder",
        components: { FileNumberInput },
        data () {
            return {
                lookups: {branches: [], titleUnits: [], escrowUnits: [], processTemplates: [], settlementTypes: []},
                orderModel: new NewFileDialogModel(),
                newOrderId: 0,
                fileNumberProcessingTooltip: null,
                isFileNumberProcessing: false,
                isFileNumberReadOnly: false,
                newCopyOrderId: null,
                okButtonDisabled: false,
                fileNumberPool: {},
                isFileSequenceInputActive: false,
                isInvalidFileNumber: false,
                branchFilePool: {},
                euFilePool: {},
                tuFilePool: {},
                filteredTU: [],
                filteredEU: [],
                isFileNumberOverridden: false,
                originalNextFileNumber: '',
                filteredProcessTemplates: []
            };
        },

        computed: {
            ...mapState({
                globalRegionId: state => state.system.globalRegionId,
                userInfo: state => state.authentication.session.user,
                systemDefaults: state => state.system.systemDefaults
            }),
            ...mapGetters([
                "lookupHelpers",
                "lookupItems",
                "regionsLookup"
            ]),
            hasTitleUnits () { return _.getNumber(this, "filteredTU.length", 0) > 0; },
            hasEscrowUnits () { return _.getNumber(this, "filteredEU.length", 0) > 0; },
            showUpdateFileSequence() {
                const self = this;
                let isAdmin = self.securitySettings.findValue("IsAdmin");
                let id = _.get(self, "fileNumberPool.fileNumberPoolID", 0);
                return isAdmin && id > 0;
            },
            existingOrderSelected() { return _.gt(this.newCopyOrderId, 0); },
            isGeneratedFileNumber() { return this.orderModel.gfNo === _.get(this, "fileNumberPool.fileNumber", null); },
            calculateNextNumberDisabled() {
                const self = this;
                return self.isFileSequenceInputActive
                    || _.isNil(self.fileNumberPool)
                    || self.fileNumberPool.fileNumberPoolID === 0
                    || self.isGeneratedFileNumber;
            },
            localSecurity() { return this.securitySettings.findValues(["CanUseOrderTemplate"]); },
            canUseOrderTemplate(){ return this.localSecurity.CanUseOrderTemplate && !this.isInvalidFileNumber; },
            showBanner() { return this.isInvalidFileNumber ||  this.isInvalidRegion || this.isInvalidBranch || this.isInvalidSettlementType; },
            isInvalidRegion() { return _.getNumber(this, "orderModel.regionID", 0) === 0; },
            isInvalidBranch() { return _.getNumber(this, "orderModel.branchID", 0) === 0; },
            isInvalidSettlementType() { return _.getNumber(this, "orderModel.settlementStatementType", -1) === -1; },
            isSettlementTypeDisabled() {
                const self = this;
                if (_.parseNumber(self.orderModel.workflowProcessTemplateID, 0) > 0) {
                    let wpt = _.find(self.lookups.processTemplates, ["id", self.orderModel.workflowProcessTemplateID]);
                    return _.parseNumber(wpt?.data, -1) > -1;
                } else {
                    return false;
                }
            },
            settlementTypes() {
                if (_.isEqual(this.orderModel.settlementStatementType, SETTLEMENT_TYPE.CSS)) {
                    return this.lookups.settlementTypes;
                } else {
                    return _.filter(this.lookups.settlementTypes, c => c.id != SETTLEMENT_TYPE.CSS);
                }
            }
        },

        created () {
            const self = this;
            self.$events.emit("create-file::enableCreateFile", { disable: true, okTooltip: "File # is required." });
            self.orderModel = new NewFileDialogModel(_.pick(self.userInfo, ["regionID", "branchID","titleUnitID", "escrowUnitID"]));
            self.lookups.settlementTypes = self.lookupHelpers.getLookupItems(self.lookupItems.SETTLEMENT_TYPES);
            self.setRegionDependantData(self.orderModel.regionID);
            self.setProcessTemplate(self.orderModel.regionID, self.orderModel.branchID);
            self.generateFileNumber();
            self.defaultSettlementType();
        },

        methods: {
            setRegionDependantData (regionID) {
                const self = this;
                self.lookups.branches = self.lookupHelpers.getBranches(regionID);
                self.lookups.titleUnits = self.lookupHelpers.getLookupItems(self.lookupItems.TITLE_UNITS, regionID);
                self.lookups.escrowUnits = self.lookupHelpers.getLookupItems(self.lookupItems.ESCROW_UNITS, regionID);
                self.filteredEU = _.cloneDeep(self.lookups.escrowUnits);
                self.filteredTU = _.cloneDeep(self.lookups.titleUnits);
                self.lookups.processTemplates = self.lookupHelpers.getLookupItems(self.lookupItems.WORKFLOW_PROCESS_TEMPLATES, regionID);
                self.filteredProcessTemplates = _.filter(self.lookups.processTemplates, item => item.regionID == regionID || item.regionID == self.globalRegionId);
                self.orderModel.branchID =
                    regionID === self.userInfo.regionID ?
                        self.userInfo.branchID :
                    !_.isEmpty(self.lookups.branches) ?
                        self.orderModel.branchID = self.lookups.branches[0].id :
                        self.orderModel.branchID = null;
            },

            setProcessTemplate(regionID, branchID) {
                const self = this;
                // Process template is defaulted in order of precedence from branch, then region if available respectively
                let isProcessTemplateUpdated = false;
                if (!_.isNil(branchID))
                    isProcessTemplateUpdated = self.setBranchProcessTemplate(branchID);

                // If process template not available from branch then attempt to set from region
                if (!isProcessTemplateUpdated)
                    self.setRegionProcessTemplate(regionID);
            },

            setBranchProcessTemplate(branchID) {
                const self = this;
                let isUpdated = false;
                // Apply a default process template from branch if available
                let branch = self.lookupHelpers.getBranch(branchID);
                if (!_.isNil(branch) && !_.isNil(branch.workflowProcessTemplateID)) {
                    self.orderModel.workflowProcessTemplateID = branch.workflowProcessTemplateID;
                    self.setSettlementType(branch.workflowProcessTemplateID);
                    isUpdated = true;
                }
                else {
                    // If no branch has no associated process template to update then reset
                    self.orderModel.workflowProcessTemplateID = null;
                }

                return isUpdated;
            },

            setRegionProcessTemplate(regionID) {
                const self = this;
                let isUpdated = false;
                // Apply a default process template from region if available
                let region = _.find(self.regionsLookup, r => r.regionID === regionID);
                if (!_.isNil(region) && !_.isNil(region.workflowProcessTemplateID)) {
                    self.orderModel.workflowProcessTemplateID = region.workflowProcessTemplateID;
                    self.setSettlementType(region.workflowProcessTemplateID, region.defaultSSType);
                    isUpdated = true;
                }
                return isUpdated;
            },

            defaultSettlementType() {
                const self = this;
                //if no ss type has been set yet, default to the region default if it has one
                if (_.isNullOrEmpty(self.orderModel.settlementStatementType)) {
                    let region = _.find(self.regionsLookup, r => r.regionID === self.orderModel.regionID);
                    self.orderModel.settlementStatementType = _.parseNumber(region?.defaultSSType, null);
                }
            },

            setSettlementType(workflowProcessTemplateID, defaultSSType = null) {
                const self = this;
                let wpt = _.find(self.lookups.processTemplates, ["id", workflowProcessTemplateID]);
                if (!_.isNullOrEmpty(wpt)) {
                    self.orderModel.settlementStatementType = _.parseNumber(wpt.data, null) ?? defaultSSType;
                } else {
                    self.orderModel.settlementStatementType = defaultSSType;
                }
            },

            async onCopyExistingClick () {
                const self = this;
                if(!self.canUseOrderTemplate) return;

                let validated = await self.validate();
                if(!validated) return;

                self.$dialog.open({
                    title: "Copy Existing File",
                    height: "80%",
                    minHeight: 700,
                    width: "80%",
                    minWidth: 1200,
                    closeOnEsc: true,
                    component: SelectExistingOrder,
                    onOk(e) {
                        self.okButtonDisabled = true;
                        let result = e.component.selectedOrder;
                        if(_.getNumber(result, "ordersID", 0) === 0) return true;
                        return self.copyExistingOrder(result.ordersID)
                            .then(newOrderId => {
                                if(newOrderId === 0) {
                                    self.$toast.error("File copy failed.");
                                    return false;
                                }
                                self.newCopyOrderId = newOrderId;
                                self.$emit("ok");
                                return true;
                            })
                            .catch(err => err);
                    }
                });
            },

            async commit() {
                const self = this;

                if(self.isFileNumberProcessing) return 0;
                if(self.newCopyOrderId) return self.newCopyOrderId;

                try {
                    let validated = await self.validate();
                    if(!validated) return 0;

                    self.fileNumberPool.userModified = (self.fileNumberPool.fileNumber !== self.orderModel.gfNo);

                    let newOrderDto = { fileNumberPool: self.fileNumberPool, order: self.orderModel}
                    let storePromise = self.$store.dispatch(ORDER_ACTIONS.CREATE_NEW_ORDER, newOrderDto);
                    let result = await self.$rqBusy.wait(storePromise);

                    if(!result.hasError)
                        return result.order.ordersID;

                    if(result.errorMessage.includes("already exists")) {
                        self.$refs.fileNumberInput.processValidation(self.orderModel.gfNo);
                        return 0;
                    }
                    self.$toast.error(result.errorMessage);
                    return 0;
                }
                catch (err) {
                    self.$toast.error("File creation failed.");
                    console.error(err);
                    return err;
                }
            },

            copyExistingOrder(sourceOrdersID) {
                const self = this;
                if(_.parseNumber(sourceOrdersID, 0) === 0)
                    return Promise.resolve(0);
                let storeParams = {
                    sourceOrdersID,
                    targetGFNo: self.orderModel.gfNo
                };
                let storePromise = self.$store.dispatch(ORDER_ACTIONS.COPY_EXISTING_ORDER, storeParams)
                return self.$rqBusy.wait(storePromise)
                    .then(result => {
                        return _.get(result, "order.ordersID", 0);
                    })
                    .catch((err) => {
                        self.$toast.error("File copy failed.");
                        console.error(err);
                        return err;
                    });
            },

            setFileSequenceInputActive(val) {
                this.isFileSequenceInputActive = val;
                if(val)
                    this.$emit("disable-ok");
                else
                    this.$emit("enable-ok");
            },

            validate() {
                const self = this;
                _.invoke(self, "$refs.fileNumberInput.updateValidation");
                return self.$nextTick()
                    .then(()=> {
                        return !self.isInvalidFileNumber;
                    });
            },

            filterEUTUDropdown(filterDependency){
                const self = this;
                if(!self.fileNumberPool || !self.fileNumberPool.isEuTuFilePool) return;

                if(filterDependency == valueDependency.EU)
                {
                    if(_.get(self, "fileNumberPool.fileNumberPoolID", 0) > 0) {
                        // filter out title unit dropdown
                        self.filteredTU = _.filter(self.lookups.titleUnits, x => {
                            let fileNumberPoolID = _.get(x, "additionalIdentity", 0);
                            return fileNumberPoolID === 0 || fileNumberPoolID === self.fileNumberPool.fileNumberPoolID;
                        });
                    }
                    else {
                        self.filteredTU = self.lookups.titleUnits;
                    }
                }
                if(filterDependency == valueDependency.TU)
                {
                    if(_.get(self, 'fileNumberPool.fileNumberPoolID', 0) > 0) {
                        // filter out escrow unit dropdown
                        self.filteredEU = _.filter(self.lookups.escrowUnits, x => {
                            let fileNumberPoolID = _.get(x, "additionalIdentity", 0);
                            return fileNumberPoolID === 0 || fileNumberPoolID === self.fileNumberPool.fileNumberPoolID;
                        });
                    }
                    else {
                        self.filteredEU = self.lookups.escrowUnits;
                    }
                }
            },

            generateFileNumber(filterDependency=''){
                const self = this;
                if(!self.systemDefaults.suggestNextFileNumber) return Promise.resolve("");
                if(self.isInvalidRegion || self.isInvalidBranch || self.isInvalidFileNumber) {
                    self.$emit(`disable-ok`);
                    return;
                } else {
                    self.$emit(`enable-ok`);
                }

                self.isFileNumberProcessing = true;
                self.isFileNumberReadOnly = true;

                var vm = {
                    ...self.orderModel,
                    ...{ valueDependency: filterDependency}
                }

                return self.$store.dispatch(ORDER_ACTIONS.GET_NEXT_FILE_NUMBER, { model: vm })
                    .then(result => {
                        if(!_.isNil(result)) {
                            self.fileNumberPool = { ...result };
                            self.originalNextFileNumber = result.nextFileNumber;
                            if(!self.isFileNumberOverridden)
                                self.orderModel.gfNo = self.fileNumberPool.fileNumber;
                        }
                        else {
                            self.$toast.error("File number pools are not configured for the selected Branch. Please see your Administrator.");
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        self.$toast.error("A problem occurred while calculating the next file number.");
                        return error;
                    })
                    .finally(() => {
                        self.isFileNumberProcessing = false;
                        self.isFileNumberReadOnly = false;
                    });
            },

            onUpdateFileSequenceOk (e){
                const self = this;
                let storePromise = self.$store.dispatch(ORDER_ACTIONS.UPDATE_FILE_NUMBER_SEQUENCE, { fileNumberPool: self.fileNumberPool, model: self.orderModel });
                return self.$rqBusy.wait(storePromise)
                    .then(result => {
                        self.originalNextFileNumber = result.nextFileNumber;
                        self.orderModel.gfNo = result.fileNumber;

                        self.$toast.success("File numbering updated successfully.");
                        self.setFileSequenceInputActive(false);
                        return result;
                    })
                    .catch(err => {
                        self.$toast.error("File numbering update failed.");
                        console.log(err);
                    });
            },

            onUpdateFileSequenceCancel(e){
                this.fileNumberPool.nextFileNumber = this.originalNextFileNumber;
                this.setFileSequenceInputActive(false);
            },

            onRevertFileNumber() {
                this.orderModel.gfNo = this.fileNumberPool.fileNumber;
                this.isFileNumberOverridden = false;
            },

            onValueChanged(e) {
                if(e.event) {
                    // means that this is user initiated
                    this.isFileNumberOverridden = (e.value !== e.previousValue && !_.isEmpty(e.value));
                    if(_.isEmpty(e.value)) {
                        this.orderModel.gfNo = this.fileNumberPool.fileNumber;
                    }
                }
            },

            onCalculateNextNumber(e) {
                if(this.calculateNextNumberDisabled) return;
                this.generateFileNumber();
            },

            onRegionChanged(e) {
                this.setRegionDependantData(this.orderModel.regionID);
                this.generateFileNumber();
            },

            onBranchChanged(e) {
                this.setProcessTemplate(this.orderModel.regionID, this.orderModel.branchID);
                if(this.isFileNumberProcessing) return;
                this.generateFileNumber();
            },

            onEscrowUnitChanged(e) {
                const self = this;
                if(self.isFileNumberProcessing) return;
                self.generateFileNumber(valueDependency.EU)
                    .then(() => {
                        self.filterEUTUDropdown(valueDependency.EU);
                    });
            },

            onProcessTemplateChanged(e) {
                const self = this;
                if(self.isFileNumberProcessing) return;
                if (_.parseNumber(e.value, 0) > 0) {
                    self.setSettlementType(e.value);
                }
            },

            onTitleUnitChanged(e) {
                const self = this;
                if(self.isFileNumberProcessing) return;
                self.generateFileNumber(valueDependency.TU)
                    .then(() => {
                        self.filterEUTUDropdown(valueDependency.TU);
                    });
            }
        }
    };
</script>
