<template>
    <div class="epn-iframe-container" v-if="showIframe">
        <iframe name="epn-iframe" id="epn-iframe" :src="epnPackageViewerUrl" class="epn-iframe" seamless></iframe>
    </div>
</template>
<script>
import { CustomEventPayload }  from "../models";

export default {
    name: "EpnPackageViewer",
    props: {
        packageID: { type: Number, required: true },
    },
    data: function() {
        return {
            showIframe: false,
            showLogin: false,
            epnPackageViewerUrl: "",
            isSubmitted: false
        };
    },
    created() {
        this.init();
        this.$emit("disable-ok");
    },
    beforeUnmount () {
        window.document.removeEventListener("savePackage", this.onSavePackage);
    },
    mounted() {
        window.document.addEventListener("savePackage", this.onSavePackage);
    },
    methods:{
        init() {
            const self = this;
            self.epnPackageViewerUrl = `${window.location.origin}/ePN.html`;
            self.showIframe = true;
        },
        onSavePackage(e) {
            const self = this;
            const data = e.detail;
            self.savePackage(self.packageID, data)
                .then(result => {
                    console.log("package", data);
                    // self.$router.push({ name: "consumer:order", params: { orderUniqueId: self.orderUniqueId }});
                    self.$emit("enable-ok");
                    self.$toast.success(`ePN Package saved successfully.`);
                })
                .catch(error => {
                    console.error(error);
                    self.$toast.error(`ePN Package creation failed, please try again.`);
                    // self.$router.push({ name: "consumer:orderdocuments", params: { orderUniqueId: self.orderUniqueId, documentId: self.documentUId }});
                });
        },
        savePackage(packageID, data) {
            const self = this;
            let apiPromise = self.$api.RecordingInformationApi.savePackage(packageID, data);
            return self.$rqBusy.wait(apiPromise);
        },
    }
}
</script>
<style lang="scss">
.epn-iframe-container {
    flex: 1;
    overflow: hidden;
    background: #EFEFEF;
    height: 100%;
}
.epn-iframe {
    position: relative;
    overflow: hidden;
    height: calc(100vh);
    width: 100%;
    border: none;
    margin: 0;
}
</style>