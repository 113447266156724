import CheckWritingMain from "./CheckWritingMain";
import { ViewActions } from "@/router/actions/check-writing";
import { OrderNoteCategory } from "@/shared/models/enums";

export default {
    name: "check-writing",
    path: "check-writing",
    component: CheckWritingMain,
    meta: {
        entity: "Check Writing",
        label: "Check Writing",
        actions: ViewActions.MAIN,
        noteCategory: OrderNoteCategory.CheckWriting,
        accessKey: "CheckWriting_ScreenAccess",
        skipSaveOnLeave: true,
        include: true,
        requireSSMapping: true
    }
};
