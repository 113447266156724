
export class OrderSigning {
    constructor(options) {
        options = options || {};
        this.orderSigningID = _.parseNumber(options.orderSigningID, 0);
        this.ordersID = _.parseNumber(options.ordersID, 0);
        this.signingDate = options.signingDate || null;
        this.signingTypeID = _.parseNumber(options.signingTypeID, null);
        this.signingStatusID = _.parseNumber(options.signingStatusID, null);
        this.duration = _.parseNumber(options.duration, null);
        this.description = options.description || null;
        this.address1 = options.address1 || null;
        this.address2 = options.address2 || null;
        this.city = options.city || null;
        this.state = options.state || null;
        this.zip = options.zip || null;
        this.orderSigningRoles = _.map(options.orderSigningRoles, r => new OrderSigningRole(r));
        this.deleteOrderSigningRoles = [];
        this.orderSigningFileScanDocuments = _.map(options.orderSigningFileScanDocuments, r => new OrderSigningFileScanDocument(r));
        this.deleteOrderSigningFileScanDocuments = [];
    }
}

export class OrderSigningRole {
    constructor(options) {
        options = options || {};
        this.orderSigningRolesID = _.parseNumber(options.orderSigningRolesID, 0);
        this.orderSigningID = _.parseNumber(options.orderSigningID, 0);
        this.rolesID = _.parseNumber(options.rolesID, 0);
        this.role = options.role || null;
        this.contact = options.contact || null;
        this.email = options.email || null;
        this.phone = options.phone || null;
    }
}

export class OrderSigningFileScanDocument {
    constructor(options) {
        options = options || {};
        this.orderSigningFileScanDocumentsID = _.parseNumber(options.orderSigningFileScanDocumentsID, 0);
        this.orderSigningID = _.parseNumber(options.orderSigningID, 0);
        this.fileScanDocumentID = _.parseNumber(options.fileScanDocumentID, 0);
        this.description = options.description || null;
        this.orderSigningDocumentStatusID = _.parseNumber(options.orderSigningDocumentStatusID, 1);
    }
}
