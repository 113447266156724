const signingDocumentStatus = {
    NeedsReview: 1,
    InProgress: 2,
    Completed: 3
};

export class SigningDocumentStatus {
    static get NeedsReview() { return signingDocumentStatus.NeedsReview; }
    static get InProgress() { return signingDocumentStatus.InProgress; }
    static get Completed() { return signingDocumentStatus.Completed; }

    // Returns a list of all status lookup items
    static lookupItems() {
        return _.map(signingDocumentStatus, (status, key) => {
            return { id: status, name: signingDocumentStatus.displayValue(status) };
        });
    }

    // Display the appropriate label based on the status id
    static displayValue(id) {
        switch (id) {
            case signingDocumentStatus.NeedsReview:
                return "Needs Review";
            case signingDocumentStatus.InProgress:
                return "In Progress";
            case signingDocumentStatus.Completed:
                return "Completed";
        }
        return "";
    }
}

const orderSigningRoleTypeOptions = {
    Cosigner: 4,
    Lender: 5,
    LoanOfficer: 6,
    LoanProcessor: 7,
    Notary: 8,
    TitleHolder: 9,
    Witness: 10
};

export class OrderSigningRoleType {
    static get Cosigner () { return orderSigningRoleTypeOptions.Cosigner; }
    static get Lender () { return orderSigningRoleTypeOptions.Lender; }
    static get LoanOfficer () { return orderSigningRoleTypeOptions.LoanOfficer; }
    static get LoanProcessor () { return orderSigningRoleTypeOptions.LoanProcessor; }
    static get Notary () { return orderSigningRoleTypeOptions.Notary; }
    static get TitleHolder () { return orderSigningRoleTypeOptions.TitleHolder; }
    static get Witness () { return orderSigningRoleTypeOptions.Witness; }

    static get lookupItems () { return _.map(orderSigningRoleTypeOptions, t => { return { id: t, name: OrderSigningRoleType.displayValue(t) }; }); }

    static displayValue (id) {
        switch(id){
            case orderSigningRoleTypeOptions.Cosigner:
                return "Cosigner";
            case orderSigningRoleTypeOptions.Lender:
                return "Lender";
            case orderSigningRoleTypeOptions.LoanOfficer:
                return "Loan Officer";
            case orderSigningRoleTypeOptions.LoanProcessor:
                return "Loan Processor";
            case orderSigningRoleTypeOptions.Notary:
                return "Notary";
            case orderSigningRoleTypeOptions.TitleHolder:
                return "Title Holder";
            case orderSigningRoleTypeOptions.Witness:
                return "Witness";            
        }
    }
}