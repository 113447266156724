<template>
    <div class="content-wrapper" id="recording-docs-grid">
        <rq-banner
            message="Please correct the highlighted errors on screen to continue."
            variant="error"
            icon="fas fa-exclamation-triangle"
            :visible="showBanner"
        />
        <rqdx-action-data-grid
            ref="dataGrid"
            title="Recording Documents"
            automation_id="tbl_recording_docs"
            :actions="selectionActions"
            :config="gridConfig"
            :data-source="gridDataSource"
            @create-epn="onCreateEpnPackage"
            @submit="onSubmitPackage"
            @original-file="onPreviewFile($event, 'original')"
            @indexed-file="onPreviewFile($event, 'indexed')"
            @delete="onDelete"
            hide-show-column-chooser
            integrated-search
            rq-editable
            :hide-clear-filters="true"
        >
        <template #toolbar>
            <ul class="nav">
                <li class="nav-item">
                    <button type="button" class="btn btn-primary" @click="onAdd">Add</button>
                </li>
                <li v-if="multipleDocuments"
                    class="nav-item"
                    v-rq-tooltip.hover :title="hasDocumentChanges ? 'Save or cancel your changes to enable' : ''">
                    <b-btn
                        automation_id="btn_reorder_documents"
                        variant="theme"
                        :disabled="readOnly || hasDocumentChanges"
                        @click="onReorderDocuments">
                        Reorder Documents
                    </b-btn>
                </li>
            </ul>
        </template>
        </rqdx-action-data-grid>
        <grid-popover
            id="epnFees"
            container="#recording-docs-grid"
            :popover="gridPopover"
            v-model:visible="gridPopover.visible"
        />
    </div>
</template>

<script setup>
    import { ref, computed, onMounted, onUnmounted, nextTick } from "vue";
    import { useRecordingStore } from "@/store/modules/recording-documents";
    import { useRqBusy, useRqToast, useRqDialog } from "@/shared/plugins/composables";
    import { GlobalEventManager } from "@/app.events";
    import { useStore } from "vuex";
    import { useLicenseStore } from "@/store/modules/license";
    import { SortableList } from "@/shared/components/rq";
    import  { FileScanApi, RecordingInformationApi }  from "@/api";
    import { RecordingInformationPackageRequestDto, RecordingPartner, EpnAuthClaims }  from "../models";
    import DxGridUtils from "@/shared/utilities/DxGridUtils";
    import DataSource from 'devextreme/data/data_source';
    import PdfViewer from "@/shared/components/rq/dialogs/EditPdfDialog";
    import GridPopover from "@/shared/components/rq/GridPopover";
    import EpnPackageViewer from "../components/EpnPackageViewer.vue";
    import { FileScanDocumentAction }  from "@document-mgmt/enums";

    const recordingStore = useRecordingStore();
    const { waitFor } = useRqBusy();
    const { toastSuccess, toastError, toastInfo } = useRqToast();
    const { showConfirm, openDialog } = useRqDialog();

    const showBanner = ref(false);

    const store = useStore();
    const licenseStore = useLicenseStore();

    const eRecordingEnabled = computed(() => licenseStore.features?.epnNextConnect);
    const dataGrid = ref(null);
    const epnClientKey = computed(() => { return recordingStore.epnClientKey });
    const ordersID = computed(() => { return store.getters.orderId });
    const fileScanDocuments = computed(() => { return recordingStore.fileScanDocuments });
    const recordingStatuses = computed(() => store.getters.lookupHelpers.getLookupItems(store.getters.lookupItems.RECORDING_STATUSES));
    const recordingDocumentTypes = computed(() => store.getters.lookupHelpers.getAllLookupItems(store.getters.lookupItems.RECORDING_DOCUMENT_TYPES));
    const recordingTypes = computed(() => store.getters.lookupHelpers.getLookupItems(store.getters.lookupItems.RECORDING_TYPES));
    // const recordingRejectionReasons = computed(() => store.getters.lookupHelpers.getAllLookupItems(store.getters.lookupItems.RECORDING_REJECTION_REASONS));
    const readOnly = computed(() => _.parseBool(store.state.isPageReadOnly));
    const multipleDocuments = computed(() => { return recordingStore.recordingDocuments.length > 1 });
    const hasDocumentChanges = computed(() => recordingStore.hasChanges);

    const gridConfig = ref({
        onEditorPreparing: onEditorPreparing,
        onInitNewRow: onInitNewRow,
        columns: [
            {
                caption: "No.",
                dataField: "recordingInformationOrder",
                dataType: "number",
                width: 50,
                sortIndex: 0,
                sortOrder: "asc",
                allowEditing: false
            },
            {
                dataField: "fileScanDocumentID",
                dataType: "string",
                caption: "File Name",
                cellTemplate: function(cellElement, cellInfo) {
                    if(_.parseNumber(cellInfo.value, 0) == 0) return;
                    let fileName = _.find(fileScanDocuments.value, { id: cellInfo.value });
                    $("<span />")
                        .addClass("text-truncate")
                        .attr("title", fileName?.name)
                        .append(fileName?.name)
                        .appendTo(cellElement);
                },
                setCellValue: function(rowData, value) {
                    rowData.fileScanDocumentID = value;
                },
                editCellTemplate: function(cellElement, cellInfo) {
                    $("<div />").dxSelectBox({
                        dataSource: new DataSource({ store: fileScanDocuments.value, key: 'id', group: 'data' }),
                        displayExpr: "name",
                        valueExpr: "id",
                        value: cellInfo.value,
                        width: 350,
                        grouped: true,
                        onValueChanged(e) {
                            cellInfo.setValue(e.value);
                        }
                    }).appendTo(cellElement);
                },
                showInColumnChooser: eRecordingEnabled.value,
                visible: eRecordingEnabled.value
            },
            {
                caption: "Doc Name",
                dataField: "description",
                dataType: "string",
            },
            {
                caption: "Doc Type",
                dataField: "documentType",
                dataType: "number",
                lookup: {
                    dataSource: recordingDocumentTypes,
                    displayExpr: "name",
                    valueExpr: "id"
                }
            },
            {
                caption: "Status",
                dataField: "recordingDocumentStatus",
                dataType: "number",
                lookup: {
                    dataSource: recordingStatuses,
                    displayExpr: "name",
                    valueExpr: "id"
                }
            },
            {
                caption: "Submitted",
                dataField: "submittedDate",
                dataType: "date"
            },
            {
                caption: "Recorded",
                dataField: "recordingDate",
                dataType: "date"
            },
            // {
            //     dataField: "liber",
            //     dataType: "string"
            // },
            {
                dataField: "book",
                dataType: "string"
            },
            {
                dataField: "page",
                dataType: "string"
            },
            // {
            //     dataField: "volume",
            //     dataType: "string"
            // },
            {
                caption: "Inst. No.",
                dataField: "instrumentNumber",
                dataType: "string",
            },
            // {
            //     caption: "Recording Type",
            //     dataField: "recordingType",
            //     dataType: "number",
            //     lookup: {
            //         dataSource: recordingTypes,
            //         displayExpr: "name",
            //         valueExpr: "id"
            //     }
            // },
            {
                caption: "Page Count",
                dataField: "pagesCount",
                dataType: "number",
            },
            {
                caption: "Coll. Fees",
                dataField: "amount",
                allowEditing: false,
                dataType: "number",
                format: {
                    type: "currency",
                    precision: 2
                },
            },
            {
                caption: "Act. Fees",
                dataField: "recordingFee",
                dataType: "number",
                format: {
                    type: "currency",
                    precision: 2
                },
                cellTemplate: DxGridUtils.amountPlusInfoCellTemplate({
                                idAppend: "fees-popup-info-",
                                handlers:{
                                    mouseover(cellElement, cellInfo, e) {
                                        let fees = _.isNullOrEmpty(cellInfo.data.feesJSON) ? null : JSON.parse(cellInfo.data.feesJSON);
                                        updateFeeGridPopover(fees, e.target.id);
                                    },
                                    mouseout(cellElement, cellInfo, e) {
                                        updateFeeGridPopover();
                                    },
                                }
                            })
            },
            {
                caption: "Package Name",
                dataField: "packageName",
                dataType: "string",
                groupIndex: eRecordingEnabled.value ? 0 : -1,
                groupCellTemplate: function (element, options) {
                    //debugger;
                    let data = _.get(options, "data.items[0]", {});
                    let packageInfo = _.isEqual(options.value, "Unrecorded") ? options.value : `<span class="text-muted">Package Name:</span> ${options.value}`;
                    let otherInfo = _.isEqual(options.value, "Unrecorded") ? "" : `, <span class="text-muted">Recorder:</span> ${data?.recorderName}, <span class="text-muted">Partner:</span> ${data?.recordingPartnerName}, <span class="text-muted">Status:</span> ${data?.packageStatusName}`;
                    $('<span>')
                        .append(`${packageInfo}${otherInfo}`)
                        .appendTo(element);


                    // const toggleCollapseHandler = function(e) {
                    //     // onToggleCollapse(item.key);
                    //     e.preventDefault();
                    //     e.stopPropagation();
                    //     e.stopImmediatePropagation();
                    // };

                    // let $packageRowDescription = $("<span class='rq-package-description'/>")
                    //     .append(`<svg class='rq-icon-symbol'><use href="#rq-fas-fa-cog"></use></svg>`)
                    //     .on("click", toggleCollapseHandler);

                    // // let $packageRowCollapseIcon = $("<span class='rq-package-collapse-icon'/>")
                    // //     .append($("<svg class='rq-icon-symbol'><use href='#rq-fas-caret-down'></use></svg>")
                    // //         .addClass(item.isExpanded ? "" : "fa-rotate-90")
                    // //     ).on("click", toggleCollapseHandler);
                    // $("<div />")
                    //     .addClass("rq-package-cell-content")
                    //     .append(displayValue)
                    //     .append($packageRowDescription);

                },
                showInColumnChooser: eRecordingEnabled.value,
                visible: eRecordingEnabled.value
            },
            // {
            //     dataField: "feeDifference",
            //     caption: "Fee Difference",
            //     allowEditing: false,
            //     dataType: "number",
            //     format: {
            //         type: "currency",
            //         precision: 2
            //     },
            // },
            // {
            //     dataField: "rejectedDate",
            //     caption: "Rejected Date",
            //     dataType: "date",
            //     setCellValue(rowData, value) {
            //         rowData.rejectedDate = value;
            //         if(_.isNil(rowData.rejectedDate)){
            //             rowData.recordingRejectionID = null;
            //             rowData.rejectedClearedDate = null;
            //         }
            //     }
            // },
            // {
            //     dataField: "recordingRejectionID",
            //     caption: "Rejected Reason",
            //     dataType: "number",
            //     lookup: {
            //         dataSource(options) {
            //             let rejReasons = recordingRejectionReasons.value;
            //             if(_.isEmpty(options?.data)) return rejReasons;
            //             let selectedValue = options?.data.recordingRejectionID;
            //             let filteredResults = _.filter(rejReasons, item => item != null && (item.inactive !== true || item.recordingRejectionID == selectedValue));
            //             return filteredResults;
            //         },
            //         displayExpr: "name",
            //         valueExpr: "id"
            //     }
            // },
            // {
            //     dataField: "rejectedClearedDate",
            //     caption: "Rejected Cleared Date",
            //     dataType: "date"
            // },
            // { type: "buttons", visible: false, showInColumnChooser: false }
        ]
    });
    const gridPopover = ref(
        {
            visible: false,
            target: null,
            gridConfig: {},
            items: [],
            title: null
        });
    const gridDataSource = ref(
        {
            key: "clientKey",
            load (loadOptions) {
                return Promise.resolve(recordingStore.recordingDocuments);
            },
            insert: onGridInsert,
            update: onGridUpdate
        });
    const selectionActions = computed(() => [
            {
                name: "create-epn",
                text: "Create ePN Package",
                eventName: "create-epn",
                requireSelection: true,
                allowMultiSelection: true,
                tooltip: `Create ePN Recording Package`,
                disabled: function(e) {
                    return createDisabled(e);
                },
                visible: eRecordingEnabled.value
            },
            // {
            //     name: "submit",
            //     text: "Submit ePN Package",
            //     eventName: "submit",
            //     requireSelection: true,
            //     allowMultiSelection: true,
            //     tooltip: `Submit ePN Recording Package`,
            //     disabled: function(e) {
            //         return submitDisabled(e);
            //     },
            //     visible: eRecordingEnabled.value
            // },
            {
                name: "view",
                text: "Preview",
                eventName: "view",
                requireSelection: true,
                tooltip: `Preview`,
                children: [
                    { name: "original-file", text: "Original File", eventName: "original-file", requireSelection: true, disabled: function(e) { return _.parseNumber(e.data.fileScanDocumentID, 0) == 0;} },
                    { name: "indexed-file", text: "Indexed File", eventName: "indexed-file", requireSelection: true, disabled: function(e) { return _.parseNumber(e.data.indexedFileScanDocumentID, 0) == 0; } },
                ],
                visible: eRecordingEnabled.value
            },
            {
                name: "delete",
                text: "Delete",
                eventName: "delete",
                requireSelection: true,
                allowMultiSelection: true,
                tooltip: `Delete Recording Document(s)`,
                disabled: function(e) {
                    return deleteDisabled(e);
                },
            }
        ]);

    function deleteDisabled(e) {
        if (_.some(e.data, i => _.parseNumber(i.recordingInformationPackageID, 0) > 0)) return "Some items are already assigned to a package."
        return false;
    }

    function createDisabled(e) {
        if (_.some(e.data, i => _.parseNumber(i.recordingInformationPackageID, 0) > 0)) return "Some items are already assigned to a package."
        if (_.some(e.data, i => _.parseNumber(i.fileScanDocumentID, 0) == 0)) return "Some items do not have a file asigned."
        return false;
    }

    // function submitDisabled(e) {console.log(e);
    //     if (_.some(e.data, i => _.parseNumber(i.packageStatus, 0) > 0 || _.parseNumber(i.recordingInformationPackageID, 0) == 0)) return "Some items are already submitted."
    //     return false;
    // }

    async function createPackageRequest(item) {
        try {
            let apiPromise = RecordingInformationApi.createPackageRequest(item);
            return await waitFor(apiPromise);
        }
        catch(err) {
            toastError("An issue occurred while creating ePN Package.");
            console.error(err);
        }
    }

    async function submitPackage(id) {
        try {
            let apiPromise = RecordingInformationApi.createPackage(id);
            return await waitFor(apiPromise);
        }
        catch(err) {
            toastError("An issue occurred while submitting ePN Package.");
            console.error(err);
        }
    }

    async function fetchData() {
        try {
            let storePromise = recordingStore.getDocuments(true);
            await waitFor(storePromise);
            refreshGrid();
        }
        catch(err) {
            toastError("An issue occurred while retrieving recording documents.");
            console.error(err);
        }
    }

    async function fetchDocumentData(fileScanDocumentID) {
        try {
            let apiPromise = FileScanApi.getFileScanDocument(fileScanDocumentID, FileScanDocumentAction.Preview);
        return await waitFor(apiPromise);
        }
        catch(err) {
            toastError("An issue occurred while retrieving document preview.");
            console.error(err);
        }
    }

    async function fetchPackageData(id) {
        try {
            let apiPromise = RecordingInformationApi.getPackageRequest(id);
            return await waitFor(apiPromise);
        }
        catch(err) {
            toastError("An issue occurred while retrieving package information.");
            console.error(err);
        }
    }

    function onAdd() {
        dataGrid.value?.gridInstance?.addRow();
    }

    async function onCreateEpnPackage(e) {
        if(!e || !e.data) return;
        let recordingInformationIDs = _.map(e.data, "recordingInformationID");
        let item = new RecordingInformationPackageRequestDto({ ordersID: ordersID.value, recordingInformationIDs, recordingType: 1, recordingPartnerID: RecordingPartner.ePN });
        let dto = await createPackageRequest(item);
        if (_.parseNumber(dto.recordingInformationPackageID, 0) > 0) launchEpnPackageViewer(dto.recordingInformationPackageID);
    }

    async function onSubmitPackage(e) {
        if(!e || !e.data) return;
        submitPackage(e.data[0].recordingInformationPackageID);
    }

    function onPreviewFile(e, type) {
        if(!e || !e.data) return;
        if (_.isEqual(type, "indexed")) {
            launchDocPreview(e.data.indexedFileScanDocumentID);
        } else {
            launchDocPreview(e.data.fileScanDocumentID);
        }
    }

    function onViewPackage(e) {
        if(!e || !e.data) return;
        launchEpnPackageViewer(e.data.recordingInformationPackageID);
    }

    function onDelete(e) {
        if(!e || !e.data) return;
        let deletedItems = e.data;
        _.forEach(deletedItems, (item) => {
            recordingStore.removeDocument(item.clientKey);
        });
        refreshGrid();
    }

     async function onGridInsert(values) {
        recordingStore.addDocument(values);
    }

    async function onGridUpdate(key, values) {
        let itemIndex = _.findIndex(recordingStore.recordingDocuments, item => item.clientKey === key);
        if(itemIndex < 0) return onGridInsert(values);

        let originalItem = _.cloneDeep(recordingStore.recordingDocuments[itemIndex]);
        let updatedItem = _.assign(recordingStore.recordingDocuments[itemIndex], values);
    }

    async function launchDocPreview(fileScanDocumentID) {
        let dto = await fetchDocumentData(fileScanDocumentID);
        let onOk = (e) => {
            refreshGrid();
        };
        openDialog({
            title: "Preview",
            height: "85%",
            width: "85%",
            resizable: true,
            component: PdfViewer,
            props: {
                content: dto.content,
                readOnly: true
            },
            okTitle: "Close",
            okOnly: true,
            onOk: onOk,
        });
    }

    async function launchEpnPackageViewer(packageID) {
        let onOk = (e) => {
            submitPackage(packageID);
            fetchData();
            return true;
        };
        let onCancel = (e) => {
            fetchData();
            return true;
        };
        let dto = await fetchPackageData(packageID);
        localStorage.setItem("epn-auth-claims", JSON.stringify(new EpnAuthClaims(epnClientKey.value)));
        localStorage.setItem("epn-package", JSON.stringify(dto));
        openDialog({
            title: "Indexing",
            height: "98%",
            width: "98%",
            adaptive: false,
            okTitle: "Submit",
            hideHeader: true,
            component: EpnPackageViewer,
            props: { packageID: packageID },
            onOk: onOk,
            onCancel: onCancel
        });
    }

    async function onSave() {
        if(!recordingStore.hasChanges) {
            toastInfo("No changes detected.");
            GlobalEventManager.saveCompleted({ success: true });
            return;
        }

        let storePromise = recordingStore.saveDocuments();
        await waitFor(storePromise);
        GlobalEventManager.saveCompleted({success: true});
        refreshGrid();
        toastSuccess("Recording documents saved.")
    }

    function onCancel() {
        if(!recordingStore.hasChanges) {
            toastInfo("No changes detected.");
            return;
        }
        const onOk = () => {
            recordingStore.cancelChanges();
            refreshGrid();
            return true;
        };
        showConfirm("Confirm Cancel", "Are you sure you want to discard your current changes?", onOk);
    }

    function refreshGrid() {
        if (dataGrid.value) {
            dataGrid.value.gridInstance.refresh();
            dataGrid.value.gridInstance.clearSelection();
        }
    }

    async function onReorderDocuments() {
        if(readOnly.value) return;
        let orderId = recordingStore.currentOrderId;
        let items = _.map(recordingStore.recordingDocuments, r => {
            return {
                id: r.id,
                description: r.description,
                ordinal: r.recordingInformationOrder
            };
        });

        openDialog({
            title: "Reorder Recording Documents",
            width: 600,
            adaptive: true,
            component: SortableList,
            props: { orderId, items},
            onOk (e)  {
                let changes = e.component.getChanges();
                if(_.isEmpty(changes)) return true;
                let request = { orderId, changes };
                waitFor(recordingStore.reorderDocuments(request))
                    .then(() => fetchData());
            }
        });
    }

    async function updateFeeGridPopover(items=null, target=null) {
        const popoverGridConfig = {
                keyExpr: "description",
                width: "400px",
                height: "300px",
                selection: { mode: "none", showCheckBoxesMode: "never" },
                columns: [
                        {
                            dataField: "description",
                            dataType: "string",
                            minWidth: 275
                        },
                        {
                            dataField: "amount",
                            dataType: "number",
                            format: {
                                type: "currency",
                                precision: 2
                            },
                            width: 125
                        },
                ],
                summary: {
                    totalItems: [
                        {
                            name: "TotalLabel",
                            column: "description",
                            alignment: "left",
                            displayFormat: "TOTAL",
                            cssClass: "rq-summary-label",
                            summaryType: "sum"
                        },
                        {
                            name: "ItemTotal",
                            column: "amount",
                            alignment: "right",
                            valueFormat: {
                                type: "currency",
                                precision: 2
                            },
                            displayFormat: "{0}",
                            summaryType: "sum"
                        },
                    ]
                }
            };
        let newID = target;
        let gridPopoverNew = _.clone(gridPopover.value);
        let lastID = _.get(gridPopoverNew, "target") || null;
        let isNewItem = !_.isEqual(newID, lastID);
        if (isNewItem) {
            if (!_.isNil(lastID)) {
                gridPopoverNew.visible = false;
            }
            gridPopoverNew.target = newID;
            gridPopoverNew.gridConfig = popoverGridConfig;
            gridPopoverNew.items = items;
            gridPopoverNew.title = "Fees";
            gridPopoverNew.visible = true;
        } else {
            gridPopoverNew.visible = !gridPopoverNew.visible;
        }
        await nextTick();
        gridPopover.value = gridPopoverNew;
    }

    function onEditorPreparing(e) {
        if(e.type === "selection" || e.row.rowType !== "data") return;

        e.editorOptions.disabled = isColumnDisabled(e.dataField, e.row.data);
    }

    function onInitNewRow(e) {
        e.data.packageName = 'Unrecorded';
        e.data.packageStatusName = 'New';
    }

    function isColumnDisabled(dataField, data) {
        if (readOnly.value) return true;
        // Stub out for any future logic for disabling columns
        var rejectedDate = data?.rejectedDate;
        if(dataField === "recordingRejectionID" && _.isNil(rejectedDate)) return true;
        if(dataField === "rejectedClearedDate" && _.isNil(rejectedDate)) return true;

        return false;
    }

    fetchData();

    const EVENT_SOURCE = "file-recording-docs"
    onMounted(() => {
        GlobalEventManager.onSave(EVENT_SOURCE, onSave);
        GlobalEventManager.onCancel(EVENT_SOURCE, onCancel);
    });
    onUnmounted(() => {
        GlobalEventManager.unregister(EVENT_SOURCE);
    });

</script>